import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Record added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};
const restoreNotificationSuccess = () => {
  notification.success({
    message: "Record restored successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  apiResult: {},
  product: null,
  purchaseId: null,
  purchaseNumber: "",
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async purchaseSubmitData({ commit }, data) {
    try {
      await commit("purchaseApiAddBeginBegin");
      const response = await DataService.post("api/purchase", data.formState);
      await commit("purchaseApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      console.log("err", err);

      await commit("purchaseApiAddBeginErr", err.Message);

      notificationError(err.Message);
    }
  },
  async purchaseChangeType({ commit }, data) {
    try {
      await commit("purchaseApiAddBeginBegin");
      const response = await DataService.post(
        "api/purchase/ChangePurchaseType",
        data
      );
      await commit("purchaseApiAddBeginSuccess", response.data.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("purchaseApiAddBeginErr", err.Message);

      // notificationError(err.Message);
    }
  },
  async purchaseGenerateNumber({ commit }, data) {
    try {
      await commit("purchaseApiAddBeginBegin");
      const response = await DataService.get(
        `api/purchase/GetNewPurchaseNumber?purchaseType=${data.purchaseType}&Year=${data.Year}`
      );
      await commit("purchaseApiGenerateSuccess", response);
    } catch (err) {
      await commit("purchaseApiAddBeginErr", err.Message);
      notificationError(err.Message);
    }
  },

  async singlePurchaseGetData({ commit }, id) {
    try {
      await commit("purchaseApiReadBegin");
      const query = await DataService.get("api/purchase/" + id);
      await commit("purchaseApiReadSuccess", query.data);
      await commit("purchaseProductApiReadSuccessFromApi", query.data.products);
    } catch (err) {
      await commit("purchaseApiDeleteErr", err.Message);
    }
  },

  async purchaseGetAllData({ commit }, {pagination, filters}) {
    try {
      await commit("purchaseApiReadBegin");
      const query = await DataService.get(
        `api/Purchase?PurchaseType=${filters.purchaseType}&PartnerId=${filters.purchasePartenaire}&PurchaseDateFrom=${filters.purchaseDateFrom}&PurchaseDateTo=${filters.purchaseDateTo}&Number=${filters.purchaseNumber}&orderBy=${pagination.orderBy}&orderByDirection=${pagination.orderByDirection}&PageSize=${pagination.pageSize}&PageNumber=${pagination.current}`
      );
      await commit("purchaseApiReadSuccess", query);
    } catch (err) {
      await commit("purchaseApiReadErr", err.Message);
    }
  },

  async purchaseGetAllDeletedData({ commit }, { pagination, filters }) {
    try {
      await commit("purchaseApiReadBegin");
      const query = await DataService.get(
        `api/Purchase/GetDeletedItem?PurchaseType=${filters.purchaseType}&PartnerId=${filters.purchasePartenaire}&PurchaseDateFrom=${filters.purchaseDateFrom}&PurchaseDateTo=${filters.purchaseDateTo}&Number=${filters.purchaseNumber}&orderBy=${pagination.orderBy}&orderByDirection=${pagination.orderByDirection}&PageSize=${pagination.pageSize}&PageNumber=${pagination.current}`
      );
      await commit("purchaseApiReadSuccess", query);
    } catch (err) {
      await commit("purchaseApiReadErr", err.Message);
    }
  },

  async purchaseInfo({ commit }) {
    try {
      await commit("purchaseApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("purchaseApiReadSuccess", query.data);
    } catch (err) {
      await commit("purchaseApiReadErr", err.Message);
    }
  },

  async purchaseUpdateData({ commit }, data) {
    try {
      await commit("purchaseApiUpdateBegin");
      await DataService.put(
        `api/purchase/${data.formState.id}`,
        data.formState
      );
      await commit("purchaseApiUpdateBeginSuccess");
      updateNotificationSuccess();
    } catch (err) {
      await commit("purchaseApiUpdateErr", err.Message);
      // notificationError(err.Message);
    }
  },
  async purchaseUpdateStatus({ commit }, data) {
    try {
      await commit("purchaseApiUpdateBegin");
      await DataService.put(`api/purchase/${data.purchase.id}`, data.purchase);
      await commit("purchaseApiUpdateBeginSuccess");
      updateNotificationSuccess();
    } catch (err) {
      await commit("purchaseApiUpdateErr", err.Message);
      // notificationError(err.Message);
    }
  },

  async purchaseApiDataSearch({ commit }, searchItem) {
    try {
      await commit("purchaseApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("purchaseApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("purchaseApiReadSuccess", query.data);
        } catch (err) {
          await commit("purchaseApiReadErr", err.Message);
        }
      }
    } catch (err) {
      await commit("purchaseApiReadErr", err.Message);
    }
  },

  async purchaseApiDataDelete({ commit }, { id }) {
    try {
      await commit("purchaseApiDeleteBegin");
      await DataService.delete(`/api/purchase/${id}`);
      await commit("purchaseApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("purchaseApiDeleteErr", err.Message);
      notificationError(err.Message);
    }
  },
  async purchaseApiDataRestore({ commit }, { id }) {
    try {
      await commit("purchaseApiDeleteBegin");
      await DataService.put(`/api/purchase/RecoverDeletedItem/${id}`, { purchaseId: id });
      await commit("purchaseApiDeleteSuccess", id);
      restoreNotificationSuccess();
    } catch (err) {
      await commit("purchaseApiDeleteErr", err.Message);
      notificationError(err.Message);
    }
  },
  async emptyPurchase({ commit }) {
    await commit("emptyPurchaseData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
