export default {
  expenseOperationApiAddBeginBegin(state) {
    state.loading = true;
  },
  expenseOperationApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiReadBegin(state) {
    state.loading = true;
  },

  expenseOperationApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiUpdateBegin(state) {
    state.loading = true;
  },

  expenseOperationApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiDeleteBegin(state) {
    state.loading = true;
  },

  expenseOperationApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiSingleDataBegin(state) {
    state.loading = true;
  },

  expenseOperationApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  expenseOperationApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiUploadBegin(state) {
    state.fileLoading = true;
  },

  expenseOperationApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  expenseOperationApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  expenseOperationApiSearchBegin(state) {
    state.loading = true;
  },

  expenseOperationApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  expenseOperationApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseOperationApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  expenseOperationApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  expenseOperationTotlaApiReadSuccess(state, data) {
    state.loading = false;
    state.totalPaginated = data.totalPaginated;
    state.totalNotPaginated = data.totalNotPaginated;

  },
  updateExpenseFiles(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex].files = data.files;
  },
  expenseOperationApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    const files = state.data.data[foundIndex].files; 
    data.files = files;
    state.data.data[foundIndex] = data;
  },
  expenseOperationApiValidateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    data.isValid = true;
    state.data.data[foundIndex] = data;
  },
  expenseOperationApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyExpense(state) {
    state.loading = false;
    state.data = [];
  },
};
