export default {
  incotermApiAddBeginBegin(state) {
    state.loading = true;
  },
  incotermApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiReadBegin(state) {
    state.loading = true;
  },

  incotermApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiUpdateBegin(state) {
    state.loading = true;
  },

  incotermApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiDeleteBegin(state) {
    state.loading = true;
  },

  incotermApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiSingleDataBegin(state) {
    state.loading = true;
  },

  incotermApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  incotermApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiUploadBegin(state) {
    state.fileLoading = true;
  },

  incotermApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  incotermApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  incotermApiSearchBegin(state) {
    state.loading = true;
  },

  incotermApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  incotermApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  incotermApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  incotermApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  incotermApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  incotermApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
