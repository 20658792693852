export default {
  hscodeApiAddBeginBegin(state) {
    state.loading = true;
  },
  hscodeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiReadBegin(state) {
    state.loading = true;
  },

  hscodeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiUpdateBegin(state) {
    state.loading = true;
  },

  hscodeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiDeleteBegin(state) {
    state.loading = true;
  },

  hscodeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiSingleDataBegin(state) {
    state.loading = true;
  },

  hscodeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  hscodeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  hscodeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  hscodeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  hscodeApiSearchBegin(state) {
    state.loading = true;
  },

  hscodeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  hscodeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  hscodeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  hscodeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  hscodeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  hscodeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
