import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "size added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async sizeSubmitData({ commit }, data) {
    try {
      await commit("sizeApiAddBeginBegin");
      const response = await DataService.post("api/Size", data.formState);
      await commit("sizeApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("sizeApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async sizeGetAllData({ commit }) {
    try {
      await commit("sizeApiReadBegin");

      const query = await DataService.get(
        `api/Size?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("sizeApiReadSuccess", query.data);
    } catch (err) {
      await commit("sizeApiReadErr", err);
    }
  },
  async sizeGetPagedData({ commit }, pagination) {
    try {
      await commit("sizeApiReadBegin");

      const query = await DataService.get(
        `api/Size?name=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.orderBy}&OrderByDirection=${pagination.sorter.order}`
      );
      await commit("sizeApiReadSuccess", query);
    } catch (err) {
      await commit("sizeApiReadErr", err);
    }
  },

  async sizeInfo({ commit }) {
    try {
      await commit("sizeApiReadBegin");
      const query = await DataService.get(
        `api/Size?Fields=id%2Cname%2Cmm&PageSize=10000&OrderBy=mm&OrderByDirection=asc`
      );
      await commit("sizeApiReadSuccess", query.data);
    } catch (err) {
      await commit("sizeApiReadErr", err);
    }
  },

  async sizeUpdateData({ commit }, data) {
    try {
      await commit("sizeApiUpdateBegin");
      const response = await DataService.put(
        `api/Size/${data.formState.id}`,
        data.formState
      );
      await commit("sizeApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("sizeApiUpdateErr", err);

      notificationError(err);
    }
  },

  async sizeApiDataSearch({ commit }, searchItem) {
    try {
      await commit("sizeApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("sizeApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("sizeApiReadSuccess", query.data);
        } catch (err) {
          await commit("sizeApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("sizeApiReadErr", err);
    }
  },

  async sizeApiDataDelete({ commit }, id) {
    try {
      await commit("sizeApiDeleteBegin");
      await DataService.delete(`/api/Size/${id}`);
      await commit("sizeApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("sizeApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptySize({ commit }) {
    await commit("emptySize");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
