<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider v-else :theme="{
    ...theme,
    rtl,
    topMenu,
    darkMode,
  }">
    <Suspense>
      <template #default>
        <router-view :key="$route.fullPath"></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from "vue3-styled-components";
import { theme } from "./config/theme/themeVariables";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import * as signalR from "@microsoft/signalr";
import { notification } from "ant-design-vue";

export default defineComponent({
  name: "App",
  components: {
    ThemeProvider,
  },
  created() {
    const userId = Cookies.get("userId");
    const logedIn = Cookies.get("logedIn");
    if (userId && logedIn) {
      this.$store.dispatch("getConnectedUserPermission", Cookies.get("userId"));
    }
  },
  mounted() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://localhost:44348/notifications")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    async function start() {
      try {
        await connection.start();
        console.log("Connected.");
        connection.on("ReceiveNotification", (user, message) => {
          console.log("message", message);
          console.log("user", user);
          if (user) {
            notification.info({
              message: user,
            });
          }
        });
      } catch (err) {
        console.log(err);
        setTimeout(start, 5000);
      }
    }

    connection.onclose(async () => {
      await start();
    });

    // Start the connection.
    start();
  },
  setup() {
    const { state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
    };
  },
});
</script>