export default {
  countryApiAddBeginBegin(state) {
    state.loading = true;
  },
  countryApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiReadBegin(state) {
    state.loading = true;
  },

  countryApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiUpdateBegin(state) {
    state.loading = true;
  },

  countryApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiDeleteBegin(state) {
    state.loading = true;
  },

  countryApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiSingleDataBegin(state) {
    state.loading = true;
  },

  countryApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  countryApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiUploadBegin(state) {
    state.fileLoading = true;
  },

  countryApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  countryApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  countryApiSearchBegin(state) {
    state.loading = true;
  },

  countryApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  countryApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  countryApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  countryApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  countryApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  countryApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
};
