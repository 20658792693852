export default {
  transportMethodApiAddBeginBegin(state) {
    state.loading = true;
  },
  transportMethodApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiReadBegin(state) {
    state.loading = true;
  },

  transportMethodApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiUpdateBegin(state) {
    state.loading = true;
  },

  transportMethodApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiDeleteBegin(state) {
    state.loading = true;
  },

  transportMethodApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiSingleDataBegin(state) {
    state.loading = true;
  },

  transportMethodApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  transportMethodApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiUploadBegin(state) {
    state.fileLoading = true;
  },

  transportMethodApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  transportMethodApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  transportMethodApiSearchBegin(state) {
    state.loading = true;
  },

  transportMethodApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  transportMethodApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  transportMethodApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  transportMethodApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  transportMethodApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  transportMethodApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyTransportMethod(state) {
    state.loading = false;
    state.data = [];
  },
};
