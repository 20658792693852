export default {
  unitApiAddBeginBegin(state) {
    state.loading = true;
  },
  unitApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiReadBegin(state) {
    state.loading = true;
  },

  unitApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiUpdateBegin(state) {
    state.loading = true;
  },

  unitApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiDeleteBegin(state) {
    state.loading = true;
  },

  unitApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiSingleDataBegin(state) {
    state.loading = true;
  },

  unitApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  unitApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiUploadBegin(state) {
    state.fileLoading = true;
  },

  unitApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  unitApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  unitApiSearchBegin(state) {
    state.loading = true;
  },

  unitApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  unitApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  unitApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  unitApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  unitApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  unitApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyUnit(state) {
    state.loading = false;
    state.data = [];
  },
};
