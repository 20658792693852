import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Cash operation added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async cashOperationSubmitData({ commit }, data) {
    try {
      await commit("cashOperationApiAddBeginBegin");
      const response = await DataService.post(
        "api/CashOperation",
        data.formState
      );
      await commit("cashOperationApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("cashOperationApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async cashOperationGetPagedData({ commit }, pagination) {
    try {
      await commit("cashOperationApiReadBegin");

      const query = await DataService.get(
        `api/CashOperation?OrderBy=name&CashOperationName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("cashOperationApiReadSuccess", query);
    } catch (err) {
      await commit("cashOperationApiReadErr", err);
    }
  },

  async cashOperationGetAllData({ commit }) {
    try {
      await commit("cashOperationApiReadBegin");

      const query = await DataService.get(
        `api/CashOperation?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("cashOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("cashOperationApiReadErr", err);
    }
  },

  async cashOperationInfo({ commit }) {
    try {
      await commit("cashOperationApiReadBegin");
      const query = await DataService.get(
        `api/CashOperation?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("cashOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("cashOperationApiReadErr", err);
    }
  },

  async cashOperationUpdateData({ commit }, data) {
    try {
      await commit("cashOperationApiUpdateBegin");
      const response = await DataService.put(
        `api/CashOperation/${data.formState.id}`,
        data.formState
      );
      await commit("cashOperationApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("cashOperationApiUpdateErr", err);

      notificationError(err);
    }
  },

  async cashOperationApiDataSearch({ commit }, searchItem) {
    try {
      await commit("cashOperationApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("cashOperationApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("cashOperationApiReadSuccess", query.data);
        } catch (err) {
          await commit("cashOperationApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("cashOperationApiReadErr", err);
    }
  },

  async cashOperationApiDataDelete({ commit }, id) {
    try {
      await commit("cashOperationApiDeleteBegin");
      await DataService.delete(`/api/CashOperation/${id}`);
      await commit("cashOperationApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("cashOperationApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyCashOperation({ commit }) {
    await commit("emptyCashOperation");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
