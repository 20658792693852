import mutations from "./mutations";
import getters from "./getters";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Quotation added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Quotation updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Quotation deleted successfully",
  });
};
const restoreNotificationSuccess = () => {
  notification.success({
    message: "Quotation restored successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  product: null,
  dealId: null,
  url: null,
  fileLoading: false,
  dealNumber: "",
  loading: false,
  error: null,
});

const actions = {
  async dealSubmitData({ commit }, data) {
    try {
      await commit("dealApiAddBeginBegin");
      const response = await DataService.post("api/Deal", data.formState);
      await commit("dealApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("dealApiAddBeginErr", err);

      notificationError(err);
    }
  },
  

  async singleDealGetData({ commit }, id) {
    try {
      await commit("dealApiReadBegin");
      const query = await DataService.get("api/deal/" + id);
      await commit("dealApiReadSuccess", query.data);
    } catch (err) {
      await commit("dealApiDeleteErr", err);
    }
  },
  async dealGenerateNumber({ commit }) {
    try {
      await commit("dealApiAddBeginBegin");
      const response = await DataService.get(
        `api/deal/GenerateNextNumber`
      );
      await commit("dealApiGenerateSuccess", response.data);
    } catch (err) {
      await commit("dealApiAddBeginErr", err.Message);
      notificationError(err.Message);
    }
  },
  async dealGetAllData({ commit }, pagination) {
    try {
      await commit("dealApiReadBegin");
      const query = await DataService.get(`api/Deal?DealPartenaire=${pagination.dealPartenaire}&DealDate=${pagination.DealDate}&PageSize=10000`);
      await commit("dealApiReadSuccess", query.data);
    } catch (err) {
      await commit("dealApiReadErr", err);
    }
  },
  async dealGetAllDeletedData({ commit }, pagination) {
    try {
      await commit("dealApiReadBegin");
      const query = await DataService.get(`api/Deal/GetDeletedItem?DealPartenaire=${pagination.dealPartenaire}&DealDate=${pagination.DealDate}&PageSize=10000`);
      await commit("dealApiReadSuccess", query.data);
    } catch (err) {
      await commit("dealApiReadErr", err);
    }
  },

  async dealInfo({ commit }) {
    try {
      await commit("dealApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("dealApiReadSuccess", query.data);
    } catch (err) {
      await commit("dealApiReadErr", err);
    }
  },

  async dealUpdateData({ commit }, data) {
    try {
      await commit("dealApiUpdateBegin");
      const response = await DataService.put(
        `api/deal/${data.formState.id}`,
        data.formState
      );
      await commit("dealApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("dealApiUpdateErr", err);
      notificationError(err);
    }
  },

  async dealApiDataSearch({ commit }, searchItem) {
    try {
      await commit("dealApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("dealApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("dealApiReadSuccess", query.data);
        } catch (err) {
          await commit("dealApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("dealApiReadErr", err);
    }
  },

  async dealApiDataDelete({ commit }, { id }) {
    try {
      await commit("dealApiDeleteBegin");
      await DataService.delete(`/api/Deal/${id}`);
      await commit("dealApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("dealApiDeleteErr", err);

      notificationError(err);
    }
  },
  async dealApiDataRestore({ commit }, { id }) {
    try {
      await commit("dealApiDeleteBegin");
      await DataService.put(`/api/Deal/RecoverDeletedItem/${id}`,{dealId: id});
      await commit("dealApiDeleteSuccess", id);
      restoreNotificationSuccess();
    } catch (err) {
      await commit("dealApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyDeal({ commit }) {
    await commit("emptyDealData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
