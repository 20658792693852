import { useProduct } from "@/composable/useProduct";
export default {
  purchaseProductApiAddBeginBegin(state) {
    state.loading = true;
  },
  purchaseProductApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiReadBegin(state) {
    state.loading = true;
  },
  purchaseProductFormApiReadBegin(state) {
    state.loading = true;
  },
  purchaseProductFormApiReadSucess(state) {
    state.loading = true;
  },

  purchaseProductApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiUpdateBegin(state) {
    state.loading = true;
  },

  purchaseProductApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiDeleteBegin(state) {
    state.loading = true;
  },

  purchaseProductApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiSingleDataBegin(state) {
    state.loading = true;
  },

  purchaseProductApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchaseProductApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiUploadBegin(state) {
    state.fileLoading = true;
  },

  purchaseProductApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  purchaseProductApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  purchaseProductApiSearchBegin(state) {
    state.loading = true;
  },

  purchaseProductApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchaseProductApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseProductApiAddBeginSuccess(state, data) {
    const { formatApiProductToList } = useProduct();
    state.loading = false;
    state.data.push(formatApiProductToList(data));
  },
  purchaseProductApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  purchaseProductApiReadSuccessFromApi(state, data) {
    const { formatApiProductToList } = useProduct();
    data.forEach((purchaseProduct) => {
      var foundIndex = state.data.findIndex((x) => x.id == purchaseProduct.id);
      if (foundIndex == -1) {
        state.data.push(formatApiProductToList(purchaseProduct));
      }
    });
    state.loading = false;
  },
  purchaseProductApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  productPurchaseApiUpdateBeginSuccess(state, data) {
    const { getProductCondition } = useProduct();

    data.conditionName = getProductCondition(data.condition);
    data.description = data.sizeId
      ? `${data.categoryName} ${data.sizeName}, ${data.weightName}, ${data.gradeName}, ${data.threadName}, ${data.rangeName}`
      : data.note;
    data.currency = data.currencyName;
    var foundIndex = state.data.findIndex((x) => x.productId == data.productId);
    state.data[foundIndex] = data;
    state.loading = false;
  },
  purchaseProductApiAddDealProductSuccess(state, data) {
    const { formatProductToList } = useProduct();
    data.forEach((purchaseProduct) => {
      var foundIndex = state.data.findIndex((x) => x.id == purchaseProduct.id);
      if (foundIndex == -1) {
        state.data.push(formatProductToList(purchaseProduct));
      }
    });
    state.loading = false;
  },
  updateProductFiles(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex].files = data.files;
  },
  purchaseProductApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.productId == id);
    state.data.splice(foundIndex, 1);
  },
  emptyProductData(state) {
    state.loading = false;
    state.data = [];
  },
};
