export default {
  cashApiAddBeginBegin(state) {
    state.loading = true;
  },
  cashApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiReadBegin(state) {
    state.loading = true;
  },

  cashApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiUpdateBegin(state) {
    state.loading = true;
  },

  cashApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiDeleteBegin(state) {
    state.loading = true;
  },

  cashApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiSingleDataBegin(state) {
    state.loading = true;
  },

  cashApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiUploadBegin(state) {
    state.fileLoading = true;
  },

  cashApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  cashApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  cashApiSearchBegin(state) {
    state.loading = true;
  },

  cashApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  cashApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  cashApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  cashApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyBank(state) {
    state.loading = false;
    state.data = [];
  },
};
