import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "payment Type added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async paymentMethodSubmitData({ commit }, data) {
    try {
      await commit("paymentMethodApiAddBeginBegin");
      const response = await DataService.post(
        "api/PaymentMethod",
        data.formState
      );
      await commit("paymentMethodApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("paymentMethodApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async paymentMethodGetAllData({ commit }) {
    try {
      await commit("paymentMethodApiReadBegin");

      const query = await DataService.get(
        `api/PaymentMethod?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("paymentMethodApiReadSuccess", query.data);
    } catch (err) {
      await commit("paymentMethodApiReadErr", err);
    }
  },

  async paymentMethodGetPagedData({ commit }, pagination) {
    try {
      await commit("paymentMethodApiReadBegin");

      const response = await DataService.get(
        `api/PaymentMethod?OrderBy=name&PaymentTypeName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("paymentMethodApiReadSuccess", response);
    } catch (err) {
      await commit("paymentMethodApiReadErr", err);
    }
  },

  async paymentMethodInfo({ commit }) {
    try {
      await commit("paymentMethodApiReadBegin");
      const query = await DataService.get(
        `api/PaymentMethod?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("paymentMethodApiReadSuccess", query.data);
    } catch (err) {
      await commit("paymentMethodApiReadErr", err);
    }
  },

  async paymentMethodUpdateData({ commit }, data) {
    try {
      await commit("paymentMethodApiUpdateBegin");
      const response = await DataService.put(
        `api/PaymentMethod/${data.formState.id}`,
        data.formState
      );
      await commit("paymentMethodApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("paymentMethodApiUpdateErr", err);

      notificationError(err);
    }
  },

  async paymentMethodApiDataSearch({ commit }, searchItem) {
    try {
      await commit("paymentMethodApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("paymentMethodApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("paymentMethodApiReadSuccess", query.data);
        } catch (err) {
          await commit("paymentMethodApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("paymentMethodApiReadErr", err);
    }
  },

  async paymentMethodApiDataDelete({ commit }, { id }) {
    try {
      await commit("paymentMethodApiDeleteBegin");
      await DataService.delete(`/api/PaymentMethod/${id}`);
      await commit("paymentMethodApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("paymentMethodApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyPaymentType({ commit }) {
    await commit("emptyPaymentType");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
