import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Product Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Product deleted",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Product updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async fillPurchaseProducts({ commit }, data) {
    try {
      await commit("purchaseProductApiAddBeginBegin");
      await commit("purchaseProductApiReadSuccess", data);
    } catch (err) {
      await commit("purchaseProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async fillPurchaseProductsFromApi({ commit }, data) {
    try {
      await commit("purchaseProductApiAddBeginBegin");
      await commit("purchaseProductApiReadSuccessFromApi", data);
    } catch (err) {
      await commit("purchaseProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async purchaseProductSubmitData({ commit }, data) {
    try {
      await commit("purchaseProductApiAddBeginBegin");
      const response = await DataService.post(
        "/api/Purchase/CreatePurchaseProduct",
        data
      );
      await commit("purchaseProductApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("purchaseProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productPurchaseApiUpdateData({ commit }, data) {
    try {
      await commit("purchaseProductApiUpdateBegin");
      const response = await DataService.put(
        "/api/purchase/EditPurchaseProductCommand",
        data
      );
      await commit("productPurchaseApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("purchaseProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productPurchaseSimpleUpdateData({ commit }, data) {
    try {
      await commit("productPurchaseApiUpdateBeginSuccess", data);
    } catch (err) {
      await commit("purchaseProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async updateProductFiles({ commit }, data) {
    await commit("updateProductFiles", data);
  },
  async purchaseProductGetData({ commit }) {
    try {
      await commit("purchaseProductApiReadBegin");
      const query = await DataService.get("/api/purchaseProducts");
      await commit("purchaseProductApiReadSuccess", query.data);
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },
  async dealProductsGetData({ commit }, data) {
    try {
      await commit("purchaseProductApiReadBegin");
      const query = await DataService.get(
        `/api/Product?DealId=${data}&addFiles=true&PageSize=10000`
      );
      await commit("purchaseProductApiReadSuccess", query.data);
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },

  async allProductsGetData({ commit }, { pagination }) {
    try {
      await commit("purchaseProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductCountry=${pagination.purchaseProductForm.countryId}&ProductSize=${pagination.purchaseProductForm.sizeId}&ProductWeight=${pagination.purchaseProductForm.weightId}&ProductCategory=${pagination.purchaseProductForm.categoryId}&ProductRange=${pagination.purchaseProductForm.rangeId}&ProductThread=${pagination.purchaseProductForm.threadId}&ProductHsCode=${pagination.purchaseProductForm.hsCodeId}&ProductGrade=${pagination.purchaseProductForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("purchaseProductApiReadSuccess", query);
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },
  async allProductsGetDataWithFiles({ commit }, { pagination }) {
    try {
      await commit("purchaseProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductCountry=${pagination.purchaseProductForm.countryId}&ProductSize=${pagination.purchaseProductForm.sizeId}&ProductWeight=${pagination.purchaseProductForm.weightId}&ProductCategory=${pagination.purchaseProductForm.categoryId}&ProductHsCode=${pagination.purchaseProductForm.hsCodeId}&&ProductRange=${pagination.purchaseProductForm.rangeId}&ProductThread=${pagination.purchaseProductForm.threadId}&ProductGrade=${pagination.purchaseProductForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("purchaseProductApiReadSuccess", query);
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },
  // async allVirtualGetDataWithFiles({ commit }, { pagination }) {
  //   try {
  //     await commit("purchaseProductApiReadBegin");
  //     const query = await DataService.get(
  //       `api/Product?ProductHsCode=${pagination.purchaseProductForm.hsCodeId}&PartnerId=${pagination.purchaseProductForm.partenaireId}&ProductType=${pagination.purchaseProductForm.purchaseProductType}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
  //     );
  //     await commit("purchaseProductApiReadSuccess", query);
  //   } catch (err) {
  //     await commit("purchaseProductApiReadErr", err);
  //   }
  // },
  async addAllDealProductsToPurchase({ commit }, dealId) {
    try {
      await commit("purchaseProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?DealId=${dealId}&addFiles=false&PageSize=10000`
      );
      await commit("purchaseProductApiAddDealProductSuccess", query.data);
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },

  async purchaseProductApiDataSearch({ commit }, searchItem) {
    try {
      await commit("purchaseProductApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("purchaseProductApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("purchaseProductApiReadSuccess", query.data);
        } catch (err) {
          await commit("purchaseProductApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("purchaseProductApiReadErr", err);
    }
  },

  async purchaseProductApiDataDelete({ commit }, product) {
    try {
      await commit("purchaseProductApiDeleteBegin");
      await DataService.delete("/api/purchase/DeletePurchaseProduct", product);
      await commit("purchaseProductApiDeleteSuccess", product.productId);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("purchaseProductApiDeleteErr", err);

      notificationError(err);
    }
  },
  async purchaseProductDataDelete({ commit }, id) {
    try {
      await commit("purchaseProductApiDeleteBegin");
      await commit("purchaseProductApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("purchaseProductApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyProductPurchaseData({ commit }) {
    await commit("emptyProductData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
