export default {
  activityApiAddBeginBegin(state) {
    state.loading = true;
  },
  activityApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiReadBegin(state) {
    state.loading = true;
  },

  activityApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiUpdateBegin(state) {
    state.loading = true;
  },

  activityApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiDeleteBegin(state) {
    state.loading = true;
  },

  activityApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiSingleDataBegin(state) {
    state.loading = true;
  },

  activityApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  activityApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiUploadBegin(state) {
    state.fileLoading = true;
  },

  activityApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  activityApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  activityApiSearchBegin(state) {
    state.loading = true;
  },

  activityApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  activityApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  activityApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  activityApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  activityApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  activityApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
