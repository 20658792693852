import mutations from "./mutations";
import Cookies from "js-cookie";
import { DataService } from "@/config/dataService/dataService";
import { setItem } from "@/utility/localStorageControl";
import { notification } from "ant-design-vue";

const state = () => ({
  login: Cookies.get("logedIn"),
  ConnectedUserPermison: [],
  loading: false,
  error: null,
});
const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const actions = {
  async login({ commit, dispatch }, payload) {
    try {
      commit("loginBegin");
      const response = await DataService.post("api/auth/login", payload);
      if (response.data && response.data.token) {
        Cookies.set("logedIn", true);
        Cookies.set("userId", response.data.userId);
        setItem("access_token", response.data.token);

        // Use dispatch to call the getConnectedUserPermission action
        await dispatch("getConnectedUserPermission", response.data.userId);

        return commit("loginSuccess", response.data.token);
      } else {
        commit("loginErr", "Veuillez vérifier vos identifiants");
        notificationError("Veuillez vérifier vos identifiants");
      }
    } catch (err) {
      commit("loginErr", err);
      notificationError("Unable to reach server");
    }
  },
  async getConnectedUserPermission({ commit }, userId) {
    try {
      const query = await DataService.get(
        `api/Permission/getAllUserPermissionByIdUser?userId=${userId}`
      );
      commit("fillConnectedUserPermession", query.data);
    } catch (err) {
      commit("loginErr", err); // You might want to handle the error appropriately
      notificationError("Unable to fetch user permissions");
    }
  },
  async logOut({ commit }) {
    try {
      commit("logoutBegin");
      Cookies.remove("logedIn");
      commit("logoutSuccess", null);
    } catch (err) {
      commit("logoutErr", err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
