import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "activity added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async activitySubmitData({ commit }, data) {
    try {
      await commit("activityApiAddBeginBegin");
      const response = await DataService.post("api/Activity", data.formState);
      await commit("activityApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("activityApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async activityGetAllData({ commit }) {
    try {
      await commit("activityApiReadBegin");

      const query = await DataService.get(
        `api/Activity?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("activityApiReadSuccess", query.data);
    } catch (err) {
      await commit("activityApiReadErr", err);
    }
  },
  async activityGetPagedData({ commit }, pagination) {
    try {
      await commit("activityApiReadBegin");

      const query = await DataService.get(
        `api/Activity?OrderBy=name&ActivityName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("activityApiReadSuccess", query);
    } catch (err) {
      await commit("activityApiReadErr", err);
    }
  },

  async activityInfo({ commit }) {
    try {
      await commit("activityApiReadBegin");
      const query = await DataService.get(
        `api/Activity?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("activityApiReadSuccess", query.data);
    } catch (err) {
      await commit("activityApiReadErr", err);
    }
  },

  async activityUpdateData({ commit }, data) {
    try {
      await commit("activityApiUpdateBegin");
      const response = await DataService.put(
        `api/Activity/${data.formState.id}`,
        data.formState
      );
      await commit("activityApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("activityApiUpdateErr", err);

      notificationError(err);
    }
  },

  async activityApiDataSearch({ commit }, searchItem) {
    try {
      await commit("activityApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("activityApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("activityApiReadSuccess", query.data);
        } catch (err) {
          await commit("activityApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("activityApiReadErr", err);
    }
  },

  async activityApiDataDelete({ commit }, { id }) {
    try {
      await commit("activityApiDeleteBegin");
      await DataService.delete(`/api/Activity/${id}`);
      await commit("activityApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("activityApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
