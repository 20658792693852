import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "file added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  parentFiles: [],
  fileId: null,
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async fileSubmitData({ commit }, data) {
    try {
      console.log("fomrdata", data);
      await commit("fileApiAddBeginBegin");
      const response = await DataService.post(
        "api/File/uploadFile",
        data.formData
      );
      console.log(response.data);
      if (response.data.fileType == 6 || response.data.fileType == "SupplierOffer") { 
        await commit("fileApiAddParentBeginSuccess", response.data);
      } else {
        await commit("fileApiAddBeginSuccess", response.data);
      }
      addNotificationSuccess();
    } catch (err) {
      await commit("fileApiAddBeginErr", data.formData);

      notificationError(err);
    }
  },

  async singleFileGetData({ commit }, id) {
    try {
      await commit("fileApiReadBegin");
      const query = await DataService.get("api/file/" + id);
      await commit("fileApiReadSuccess", query.data);
    } catch (err) {
      await commit("fileApiDeleteErr", err);
    }
  },

  async getFileByIdItemAndFileType({ commit }, formState) {
    try {
      await commit("fileApiReadBegin");
      const query = await DataService.post("api/file/GetFileByIdItemAndFileType", formState);
      await commit("fileApiReadSuccess", query.data);
    } catch (err) {
      await commit("fileApiDeleteErr", err);
    }
  },

  async fileGetAllData({ commit }) {
    try {
      await commit("fileApiReadBegin");
      const query = await DataService.get(`api/File?PageSize=100000`);
      await commit("fileApiReadSuccess", query.data);
    } catch (err) {
      await commit("fileApiReadErr", err);
    }
  },

  async fileInfo({ commit }) {
    try {
      await commit("fileApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("fileApiReadSuccess", query.data);
    } catch (err) {
      await commit("fileApiReadErr", err);
    }
  },

  async fileUpdateData({ commit }, data) {
    try {
      await commit("fileApiUpdateBegin");
      const response = await DataService.put(
        `api/file/${data.formState.id}`,
        data.formState
      );
      await commit("fileApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("fileApiUpdateErr", err);

      notificationError(err);
    }
  },

  async fileApiDataSearch({ commit }, searchItem) {
    try {
      await commit("fileApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("fileApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("fileApiReadSuccess", query.data);
        } catch (err) {
          await commit("fileApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("fileApiReadErr", err);
    }
  },

  async fileApiDataDelete({ commit }, { id }) {
    try {
      await commit("fileApiDeleteBegin");
      await DataService.delete(`/api/File/${id}`);
      await commit("fileApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("fileApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyFile({ commit }) {
    await commit("emptyFileData");
  },
  async setIntialFiles({ commit }, files) {
    await commit("setIntialFiles", files);
  },
  async setParentlFiles({ commit }, files) {
    await commit("setParentlFiles", files);
  },
  async removeFile({ commit }, file) {
    if (file.success || file.id) {
      try {
        await commit("fileApiDeleteBegin");
        await DataService.delete(`/api/File/${file.id}`);

        if (file.fileType == 6 || file.fileType == "SupplierOffer") {
          await commit("fileApiDeleteParentSuccess", file.id);
        } else {
          await commit("fileApiDeleteSuccess", file.id);
        }
        deleteNotificationSuccess();
      } catch (err) {
        await commit("fileApiDeleteErr", err);

        notificationError(err);
      }
    } else {
      await commit("fileApiDeleteSuccess", file.uid);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
