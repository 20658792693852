import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Cash operation type added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async cashOperationTypeSubmitData({ commit }, data) {
    try {
      await commit("cashOperationTypeApiAddBeginBegin");
      const response = await DataService.post(
        "api/CashOperationType",
        data.formState
      );
      await commit("cashOperationTypeApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("cashOperationTypeApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async cashOperationTypeGetPagedData({ commit }, pagination) {
    try {
      await commit("cashOperationTypeApiReadBegin");

      const query = await DataService.get(
        `api/CashOperationType?OrderBy=name&name=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("cashOperationTypeApiReadSuccess", query);
    } catch (err) {
      await commit("cashOperationTypeApiReadErr", err);
    }
  },

  async cashOperationTypeGetAllData({ commit }) {
    try {
      await commit("cashOperationTypeApiReadBegin");

      const query = await DataService.get(
        `api/CashOperationType?OrderBy=name&PageCashOperationType=10000000`
      );
      await commit("cashOperationTypeApiReadSuccess", query.data);
    } catch (err) {
      await commit("cashOperationTypeApiReadErr", err);
    }
  },

  async cashOperationTypeInfo({ commit }) {
    try {
      await commit("cashOperationTypeApiReadBegin");
      const query = await DataService.get(
        `api/CashOperationType?OrderBy=name&Fields=id%2Cname%2CsizeId&PageSize=10000000`
      );
      await commit("cashOperationTypeApiReadSuccess", query.data);
    } catch (err) {
      await commit("cashOperationTypeApiReadErr", err);
    }
  },

  async cashOperationTypeUpdateData({ commit }, data) {
    try {
      await commit("cashOperationTypeApiUpdateBegin");
      const response = await DataService.put(
        `api/CashOperationType/${data.formState.id}`,
        data.formState
      );
      await commit("cashOperationTypeApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("cashOperationTypeApiUpdateErr", err);

      notificationError(err);
    }
  },

  async cashOperationTypeApiDataSearch({ commit }, searchItem) {
    try {
      await commit("cashOperationTypeApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("cashOperationTypeApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("cashOperationTypeApiReadSuccess", query.data);
        } catch (err) {
          await commit("cashOperationTypeApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("cashOperationTypeApiReadErr", err);
    }
  },

  async cashOperationTypeApiDataDelete({ commit }, id) {
    try {
      await commit("cashOperationTypeApiDeleteBegin");
      await DataService.delete(`/api/CashOperationType/${id}`);
      await commit("cashOperationTypeApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("cashOperationTypeApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
