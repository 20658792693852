export default {
  cashOperationApiAddBeginBegin(state) {
    state.loading = true;
  },
  cashOperationApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiReadBegin(state) {
    state.loading = true;
  },

  cashOperationApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiUpdateBegin(state) {
    state.loading = true;
  },

  cashOperationApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiDeleteBegin(state) {
    state.loading = true;
  },

  cashOperationApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiSingleDataBegin(state) {
    state.loading = true;
  },

  cashOperationApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashOperationApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiUploadBegin(state) {
    state.fileLoading = true;
  },

  cashOperationApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  cashOperationApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  cashOperationApiSearchBegin(state) {
    state.loading = true;
  },

  cashOperationApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashOperationApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  cashOperationApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  cashOperationApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  cashOperationApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyBank(state) {
    state.loading = false;
    state.data = [];
  },
};
