export default {
  partnerApiAddBeginBegin(state) {
    state.loading = true;
  },
  partnerApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiReadBegin(state) {
    state.loading = true;
  },

  partnerApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiUpdateBegin(state) {
    state.loading = true;
  },

  partnerApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiDeleteBegin(state) {
    state.loading = true;
  },

  partnerApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiSingleDataBegin(state) {
    state.loading = true;
  },

  partnerApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  partnerApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiUploadBegin(state) {
    state.fileLoading = true;
  },

  partnerApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  partnerApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  partnerApiSearchBegin(state) {
    state.loading = true;
  },

  partnerApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  partnerApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  partnerApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  partnerApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  partnerApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    if (state.data.data.length) {
      let foundIndex = state.data.data.findIndex((x) => x.id == data.id);
      state.data.data[foundIndex] = data;
    } else {
      state.data.data = data;
    }
  },
  partnerApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyPartnerData(state) {
    state.loading = false;
    state.data = [];
    state.dealId = null;
  },
};
