export default {
  sizeApiAddBeginBegin(state) {
    state.loading = true;
  },
  sizeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiReadBegin(state) {
    state.loading = true;
  },

  sizeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiUpdateBegin(state) {
    state.loading = true;
  },

  sizeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiDeleteBegin(state) {
    state.loading = true;
  },

  sizeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiSingleDataBegin(state) {
    state.loading = true;
  },

  sizeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  sizeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  sizeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  sizeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  sizeApiSearchBegin(state) {
    state.loading = true;
  },

  sizeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  sizeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sizeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  sizeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  sizeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  sizeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptySize(state) {
    state.loading = false;
    state.data = [];
  },
};
