import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Payment Term Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Payment Term deleted",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Payment Term updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async salePaymentTermsGetData({ commit }, data) {
    try {
      await commit("salePaymentTermsApiReadBegin");
      const query = await DataService.get(
        `/api/PaymentTerms?SaleId=${data}&PageSize=10000`
      );
      await commit("salePaymentTermsApiReadSuccess", query.data);
    } catch (err) {
      await commit("salePaymentTermsApiReadErr", err);
    }
  },
  async salePaymentTermsSubmitData({ commit }, data) {
    try {
      await commit("salePaymentTermsApiAddBeginBegin");
      const response = await DataService.post("/api/PaymentTerms", data);
      response.data.index = data.index;
      await commit("salePaymentTermsApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("salePaymentTermsApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async salePaymentTermsUpdateData({ commit }, data) {
    try {
      await commit("salePaymentTermsApiUpdateBegin");
      await DataService.put("/api/PaymentTerms/" + data.id, data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("salePaymentTermsApiUpdateSuccess", err);

      notificationError(err);
    }
  },

  async salePaymentTermsApiDataDelete({ commit }, data) {
    try {
      await commit("salePaymentTermsApiDeleteBegin");
      await DataService.delete("/api/PaymentTerms/" + data.id);
      await commit("salePaymentTermsApiDeleteSuccess", data.id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("salePaymentTermsApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyPaymentTermsSaleData({ commit }) {
    await commit("emptyPaymentTermsData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
