//import productInterface from "../interfaces/productInterface";

export const useProduct = () => {
  const getProductCondition = (condition) => {
    switch (condition) {
      case 1:
        return "Brand New";
      case 2:
        return "Unused Inspected";
      case 3:
        return "Unused not inspected";
      case 4:
        return "Unused Needs Refurbishment";
      case 5:
        return "Used";
      case 7:
        return "Scrap";
      default:
        return "Not Defined";
    }
  };
  const formatProductToList = (purchaseProduct) => {
    let quantity = purchaseProduct.quantity ?? purchaseProduct.quantityInitial;

    return {
      id: purchaseProduct.id,
      productId: purchaseProduct.id,
      description: purchaseProduct.sizeId
        ? `${purchaseProduct.categoryName} ${purchaseProduct.sizeName}, ${purchaseProduct.weightName}, ${purchaseProduct.gradeName}, ${purchaseProduct.threadName}, ${purchaseProduct.rangeName}`
        : purchaseProduct.note,
      dealIsPartialSale: purchaseProduct.deal.partialSale,
      unitPrice: purchaseProduct.priceForSales,
      currency: purchaseProduct.currencyName,
      partenaireName: purchaseProduct.partenaireName,
      quantityVirtuelle: purchaseProduct.quantityVirtuelle,
      quantityInStock: purchaseProduct.quantityInStock,
      quantityInitial: purchaseProduct.quantityInitial,
      quantity,
      price: purchaseProduct.price,
      unitOfMeasureId:
        purchaseProduct.unitOfMeasureId ??
        purchaseProduct.product.unitOfMeasureId,
      unitOfMeasureName:
        purchaseProduct.unitOfMeasureName ??
        purchaseProduct.product.unitOfMeasureName,
      isPartialSale: purchaseProduct.isPartialSale,
      deliveryTime: purchaseProduct.deliveryTime,
      productType: purchaseProduct.productType,
      numberPackage: purchaseProduct.numberPackage,
      totalPrice: purchaseProduct.totalPrice,
      availability: purchaseProduct.availability,
      conditionName: getProductCondition(purchaseProduct.condition),
      condition: purchaseProduct.condition,
      currencyId: purchaseProduct.currencyId,
      pslId: purchaseProduct.pslId,
      drift: purchaseProduct.drift,
      currencyName: purchaseProduct.currencyName,
      incotermId: purchaseProduct.incotermId,
      incotermName: purchaseProduct.incotermName,
      stockLocation: purchaseProduct.stockLocation,
      stockLocationMaps:
        purchaseProduct.stockLocationMaps ??
        purchaseProduct.product.stockLocationMaps,
      countryId: purchaseProduct.countryId,
      countryName: purchaseProduct.countryName,
      originCountryId: purchaseProduct.originCountryId,
      originCountryName: purchaseProduct.originCountryName,
      categoryId: purchaseProduct.categoryId,
      categoryName: purchaseProduct.categoryName,
      sizeId: purchaseProduct.sizeId,
      sizeName: purchaseProduct.sizeName,
      gradeId: purchaseProduct.gradeId,
      gradeName: purchaseProduct.gradeName,
      weightId: purchaseProduct.weightId,
      weightName: purchaseProduct.weightName,
      rangeId: purchaseProduct.rangeId,
      rangeName: purchaseProduct.rangeName,
      threadId: purchaseProduct.threadId,
      threadName: purchaseProduct.threadName,
      hsCodeId: purchaseProduct.hsCodeId,
      hsCodeName: purchaseProduct.hsCodeName,
      note: purchaseProduct.note,
    };
  };
  const formatApiProductToList = (apiProduct) => {
    const quantity = apiProduct.quantity ?? apiProduct.product.quantityInitial;
    return {
      id: apiProduct.product.id,
      productId: apiProduct.productId,
      description: apiProduct.product.sizeId
        ? `${apiProduct.categoryName} ${apiProduct.sizeName}, ${apiProduct.weightName}, ${apiProduct.gradeName}, ${apiProduct.threadName}, ${apiProduct.rangeName}`
        : apiProduct.product.note,
      dealIsPartialSale: apiProduct.product.deal.partialSale,
      currency: apiProduct.product.currencyName,
      partenaireName: apiProduct.product.partenaireName,
      conditionName: getProductCondition(apiProduct.product.condition),
      quantityVirtuelle: apiProduct.product.quantityVirtuelle,
      quantityInStock: apiProduct.product.quantityInStock,
      quantityInitial: apiProduct.product.quantityInitial,
      quantity: quantity,
      price: apiProduct.price,
      unitOfMeasureId:
        apiProduct.unitOfMeasureId ?? apiProduct.product.unitOfMeasureId,
      unitOfMeasureName:
        apiProduct.unitOfMeasureName ?? apiProduct.product.unitOfMeasureName,
      isPartialSale:
        apiProduct.isPartialSale ?? apiProduct.product.isPartialSale,
      deliveryTime: apiProduct.deliveryTime ?? apiProduct.product.deliveryTime,
      productType: apiProduct.productType ?? apiProduct.product.productType,
      numberPackage:
        apiProduct.numberPackage ?? apiProduct.product.numberPackage,
      totalPrice: apiProduct.totalPrice ?? apiProduct.product.totalPrice,
      availability: apiProduct.availability ?? apiProduct.product.availability,
      condition: apiProduct.condition ?? apiProduct.product.condition,
      currencyId: apiProduct.currencyId ?? apiProduct.product.currencyId,
      currencyName: apiProduct.currencyName ?? apiProduct.product.currencyName,
      incotermId: apiProduct.incotermId ?? apiProduct.product.incotermId,
      incotermName: apiProduct.incotermName ?? apiProduct.product.incotermName,
      pslId: apiProduct.pslId ?? apiProduct.product.pslId,
      drift: apiProduct.drift ?? apiProduct.product.drift,
      stockLocation:
        apiProduct.stockLocation ?? apiProduct.product.stockLocation,
      stockLocationMaps:
        apiProduct.stockLocationMaps ?? apiProduct.product.stockLocationMaps,
      countryId: apiProduct.countryId ?? apiProduct.product.countryId,
      countryName: apiProduct.countryName ?? apiProduct.product.countryName,
      /// A refaire
      originCountryId: apiProduct.originCountryId ?? apiProduct.product.originCountryId,
      originCountryName: apiProduct.countryOriginName ?? apiProduct.product.originCountryName,
      ////
      categoryId: apiProduct.categoryId ?? apiProduct.product.categoryId,
      categoryName: apiProduct.categoryName ?? apiProduct.product.categoryName,
      sizeId: apiProduct.sizeId ?? apiProduct.product.sizeId,
      sizeName: apiProduct.sizeName ?? apiProduct.product.sizeName,
      gradeId: apiProduct.gradeId ?? apiProduct.product.gradeId,
      gradeName: apiProduct.gradeName ?? apiProduct.product.gradeName,
      weightId: apiProduct.weightId ?? apiProduct.product.weightId,
      weightName: apiProduct.weightName ?? apiProduct.product.weightName,
      rangeId: apiProduct.rangeId ?? apiProduct.product.rangeId,
      rangeName: apiProduct.rangeName ?? apiProduct.product.rangeName,
      threadId: apiProduct.threadId ?? apiProduct.product.threadId,
      threadName: apiProduct.threadName ?? apiProduct.product.threadName,
      hsCodeId: apiProduct.hsCodeId ?? apiProduct.product.hsCodeId,
      hsCodeName: apiProduct.hsCodeName ?? apiProduct.product.hsCodeName,
      note: apiProduct.note ?? apiProduct.product.note,
    };
  };
  const formatProductToSaleProduct = (saleProduct) => {
    return {
      productId: saleProduct.id,
      quantity: 0,
      description: saleProduct.description,
      condition: saleProduct.condition,
      quantityInStock: saleProduct.quantityInStock,
      quantityInitial: saleProduct.quantityInitial,
      price: saleProduct.unitPrice,
      saleId: "",
      product: saleProduct,
    };
  };

  return {
    formatProductToList,
    getProductCondition,
    formatApiProductToList,
    formatProductToSaleProduct,
  };
};
