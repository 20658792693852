import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "inspectionSOW added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async inspectionSOWSubmitData({ commit }, data) {
    try {
      await commit("inspectionSOWApiAddBeginBegin");
      const response = await DataService.post("api/InspectionSOW", data.formState);
      await commit("inspectionSOWApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("inspectionSOWApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async inspectionSOWGetAllData({ commit }) {
    try {
      await commit("inspectionSOWApiReadBegin");

      const query = await DataService.get(
        `api/InspectionSOW?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("inspectionSOWApiReadSuccess", query.data);
    } catch (err) {
      await commit("inspectionSOWApiReadErr", err);
    }
  },

  async inspectionSOWGetPagedData({ commit }, pagination) {
    try {
      await commit("inspectionSOWApiReadBegin");

      const response = await DataService.get(
        `api/InspectionSOW?OrderBy=name&InspectionSOWName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("inspectionSOWApiReadSuccess", response);
    } catch (err) {
      await commit("inspectionSOWApiReadErr", err);
    }
  },

  async inspectionSOWInfo({ commit }) {
    try {
      await commit("inspectionSOWApiReadBegin");
      const query = await DataService.get(
        `api/InspectionSOW?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("inspectionSOWApiReadSuccess", query.data);
    } catch (err) {
      await commit("inspectionSOWApiReadErr", err);
    }
  },

  async inspectionSOWUpdateData({ commit }, data) {
    try {
      await commit("inspectionSOWApiUpdateBegin");
      const response = await DataService.put(
        `api/InspectionSOW/${data.formState.id}`,
        data.formState
      );
      await commit("inspectionSOWApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("inspectionSOWApiUpdateErr", err);

      notificationError(err);
    }
  },

  async inspectionSOWApiDataSearch({ commit }, searchItem) {
    try {
      await commit("inspectionSOWApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("inspectionSOWApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("inspectionSOWApiReadSuccess", query.data);
        } catch (err) {
          await commit("inspectionSOWApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("inspectionSOWApiReadErr", err);
    }
  },

  async inspectionSOWApiDataDelete({ commit }, { id }) {
    try {
      await commit("inspectionSOWApiDeleteBegin");
      await DataService.delete(`/api/InspectionSOW/${id}`);
      await commit("inspectionSOWApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("inspectionSOWApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyInspectionSOW({ commit }) {
    await commit("emptyInspectionSOW");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
