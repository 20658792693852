import axios from "axios";
import { getItem } from "../../utility/localStorageControl";
import Cookies from "js-cookie";

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const FRONT_URL = process.env.VUE_APP_SUB_ROUTE;

const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
  },
});

function tokenIsExpired() {
  var token = getItem("access_token");
  const expiry = JSON.parse(atob(token.split(".")[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
}

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }
  static getFile(path = "") {
    return client({
      method: "GET",
      responseType: "arraybuffer",
      url: path,
      headers: { ...authHeader(), Accept: "application/octet-stream" },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    const { data } = response;
    if ((response && response.status == 200) || response.status == 201) {
      return data;
    }
  },
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 401 && tokenIsExpired()) {
        Cookies.remove("logedIn");
        window.location.replace(FRONT_URL + "auth/login");
      }
      if (
        response.status === 403
      ) {
        return Promise.reject("Permission denied");
      }
      if (
        response.status === 400 ||
        (response.data.Succeeded && response.data.Succeeded == false)
      ) {
        if (Array.isArray(response.data.Errors) && response.data.Errors.length > 0) {
          return Promise.reject(response.data.Errors[0]);
        }
        return Promise.reject(response.data.Message);
      }
      if (response.status === 500) {
        if (response.data.Message) {
          return Promise.reject(response.data.Message ?? response.data);
        }
        return Promise.reject(response.data);
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(response.data);
  }
);
export { DataService };
