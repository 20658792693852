export default {
  inspectionSOWApiAddBeginBegin(state) {
    state.loading = true;
  },
  inspectionSOWApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiReadBegin(state) {
    state.loading = true;
  },

  inspectionSOWApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiUpdateBegin(state) {
    state.loading = true;
  },

  inspectionSOWApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiDeleteBegin(state) {
    state.loading = true;
  },

  inspectionSOWApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiSingleDataBegin(state) {
    state.loading = true;
  },

  inspectionSOWApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  inspectionSOWApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiUploadBegin(state) {
    state.fileLoading = true;
  },

  inspectionSOWApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  inspectionSOWApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  inspectionSOWApiSearchBegin(state) {
    state.loading = true;
  },

  inspectionSOWApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  inspectionSOWApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  inspectionSOWApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  inspectionSOWApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  inspectionSOWApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  inspectionSOWApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },

  emptyInspectionSOW(state) {
    state.loading = false;
    state.data = [];
  },
};
