export default {
  salePaymentTermsApiAddBeginBegin(state) {
    state.loading = true;
  },
  salePaymentTermsApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiReadBegin(state) {
    state.loading = true;
  },
  salePaymentTermsFormApiReadBegin(state) {
    state.loading = true;
  },
  salePaymentTermsFormApiReadSucess(state) {
    state.loading = true;
  },

  salePaymentTermsApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiUpdateBegin(state) {
    state.loading = true;
  },

  salePaymentTermsApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiDeleteBegin(state) {
    state.loading = true;
  },

  salePaymentTermsApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiSingleDataBegin(state) {
    state.loading = true;
  },

  salePaymentTermsApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  salePaymentTermsApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiUploadBegin(state) {
    state.fileLoading = true;
  },

  salePaymentTermsApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  salePaymentTermsApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  salePaymentTermsApiSearchBegin(state) {
    state.loading = true;
  },

  salePaymentTermsApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  salePaymentTermsApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  salePaymentTermsApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  salePaymentTermsApiUpdateSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex].files = data.files;
  },
  salePaymentTermsApiAddBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.index == data.index);
    delete data.index;
    state.data[foundIndex] = data;
  },
  salePaymentTermsApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.productId == id);
    state.data.splice(foundIndex, 1);
  },
  emptyPaymentTermsData(state) {
    state.loading = false;
    state.data = [];
  },
};
