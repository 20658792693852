export default {
  weightApiAddBeginBegin(state) {
    state.loading = true;
  },
  weightApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiReadBegin(state) {
    state.loading = true;
  },

  weightApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiUpdateBegin(state) {
    state.loading = true;
  },

  weightApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiDeleteBegin(state) {
    state.loading = true;
  },

  weightApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiSingleDataBegin(state) {
    state.loading = true;
  },

  weightApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  weightApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiUploadBegin(state) {
    state.fileLoading = true;
  },

  weightApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  weightApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  weightApiSearchBegin(state) {
    state.loading = true;
  },

  weightApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  weightApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  weightApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  weightApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  weightApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  weightApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
