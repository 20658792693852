import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Product Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Product deleted",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Product updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async fillSaleProducts({ commit }, data) {
    try {
      await commit("saleProductApiAddBeginBegin");
      await commit("saleProductApiReadSuccess", data);
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async fillSaleProductsFromApi({ commit }, data) {
    try {
      await commit("saleProductApiAddBeginBegin");
      await commit("saleProductApiReadSuccessFromApi", data);
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async saleProductSubmitData({ commit }, data) {
    try {
      await commit("saleProductApiAddBeginBegin");
      const response = await DataService.post(
        "/api/Sale/CreateSaleProduct",
        data
      );
      await commit("saleProductApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productSaleUpdateData({ commit }, data) {
    try {
      await commit("saleProductApiUpdateBegin");
      const response = await DataService.put(
        "/api/sale/EditSaleProductCommand",
        data
      );
      await commit("saleProductApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async updateProductFiles({ commit }, data) {
    await commit("updateProductFiles", data);
  },
  async saleProductGetData({ commit }) {
    try {
      await commit("saleProductApiReadBegin");
      const query = await DataService.get("/api/saleProducts");
      await commit("saleProductApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },
  async dealProductsGetData({ commit }, data) {
    try {
      await commit("saleProductApiReadBegin");
      const query = await DataService.get(
        `/api/Product?DealId=${data}&addFiles=true&PageSize=10000`
      );
      await commit("saleProductApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },

  async allProductsGetData({ commit }, { pagination }) {
    try {
      await commit("saleProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductCountry=${pagination.saleProductForm.countryId}&ProductSize=${pagination.saleProductForm.sizeId}&ProductWeight=${pagination.saleProductForm.weightId}&ProductCategory=${pagination.saleProductForm.categoryId}&ProductRange=${pagination.saleProductForm.rangeId}&ProductThread=${pagination.saleProductForm.threadId}&ProductHsCode=${pagination.saleProductForm.hsCodeId}&ProductGrade=${pagination.saleProductForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("saleProductApiReadSuccess", query);
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },
  async allProductsGetDataWithFiles({ commit }, { pagination }) {
    try {
      await commit("saleProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductCountry=${pagination.saleProductForm.countryId}&ProductSize=${pagination.saleProductForm.sizeId}&ProductWeight=${pagination.saleProductForm.weightId}&ProductCategory=${pagination.saleProductForm.categoryId}&ProductHsCode=${pagination.saleProductForm.hsCodeId}&&ProductRange=${pagination.saleProductForm.rangeId}&ProductThread=${pagination.saleProductForm.threadId}&ProductGrade=${pagination.saleProductForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("saleProductApiReadSuccess", query);
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },
  // async allVirtualGetDataWithFiles({ commit }, { pagination }) {
  //   try {
  //     await commit("saleProductApiReadBegin");
  //     const query = await DataService.get(
  //       `api/Product?ProductHsCode=${pagination.saleProductForm.hsCodeId}&PartnerId=${pagination.saleProductForm.partenaireId}&ProductType=${pagination.saleProductForm.saleProductType}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
  //     );
  //     await commit("saleProductApiReadSuccess", query);
  //   } catch (err) {
  //     await commit("saleProductApiReadErr", err);
  //   }
  // },
  async addAllDealProductsToSale({ commit }, dealId) {
    try {
      await commit("saleProductApiReadBegin");
      const query = await DataService.get(
        `api/Product?DealId=${dealId}&addFiles=false&PageSize=10000`
      );
      await commit("saleProductApiAddDealProductSuccess", query.data);
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },

  async saleProductApiDataSearch({ commit }, searchItem) {
    try {
      await commit("saleProductApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("saleProductApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("saleProductApiReadSuccess", query.data);
        } catch (err) {
          await commit("saleProductApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("saleProductApiReadErr", err);
    }
  },

  async saleProductApiDataDelete({ commit }, product) {
    try {
      await commit("saleProductApiDeleteBegin");
      await DataService.delete("/api/sale/DeleteSaleProduct", product);
      await commit("saleProductApiDeleteSuccess", product.productId);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("saleProductApiDeleteErr", err);

      notificationError(err);
    }
  },
  async saleProductDataDelete({ commit }, id) {
    try {
      await commit("saleProductApiDeleteBegin");
      await commit("saleProductApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("saleProductApiDeleteErr", err);

      notificationError(err);
    }
  },
  async productSaleApiUpdateData({ commit }, data) {
    try {
      await commit("saleProductApiUpdateBegin");
      const response = await DataService.put(
        "/api/sale/EditSaleProductCommand",
        data
      );
      await commit("productSaleApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productSaleSimpleUpdateData({ commit }, data) {
    try {
      await commit("productSaleApiUpdateBeginSuccess", data);
    } catch (err) {
      await commit("saleProductApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async emptyProductSaleData({ commit }) {
    await commit("emptyProductData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
