export default {
  bankApiAddBeginBegin(state) {
    state.loading = true;
  },
  bankApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiReadBegin(state) {
    state.loading = true;
  },

  bankApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiUpdateBegin(state) {
    state.loading = true;
  },

  bankApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiDeleteBegin(state) {
    state.loading = true;
  },

  bankApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiSingleDataBegin(state) {
    state.loading = true;
  },

  bankApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiUploadBegin(state) {
    state.fileLoading = true;
  },

  bankApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  bankApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  bankApiSearchBegin(state) {
    state.loading = true;
  },

  bankApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  bankApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  bankApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  bankApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyBank(state) {
    state.loading = false;
    state.data = [];
  },
};
