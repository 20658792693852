import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";

const state = () => ({
  data: [],
  url: null,
  loading: false,
  error: null,
});

const actions = {
  async historyGetData({ commit }, target) {
    try {
      await commit("historyApiReadBegin");
      const query = await DataService.get(
        `/api/${target.entity}/GetHistory?${target.entity}Id=${target.entityId}`
      );
      await commit("historyApiReadSuccess", query.data);
    } catch (err) {
      await commit("historyApiReadErr", err);
    }
  },
  async emptyHistoryData({ commit }) {
    await commit("emptyHistoryData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
 