export default {
  threadApiAddBeginBegin(state) {
    state.loading = true;
  },
  threadApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiReadBegin(state) {
    state.loading = true;
  },

  threadApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiUpdateBegin(state) {
    state.loading = true;
  },

  threadApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiDeleteBegin(state) {
    state.loading = true;
  },

  threadApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiSingleDataBegin(state) {
    state.loading = true;
  },

  threadApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  threadApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiUploadBegin(state) {
    state.fileLoading = true;
  },

  threadApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  threadApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  threadApiSearchBegin(state) {
    state.loading = true;
  },

  threadApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  threadApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  threadApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  threadApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  threadApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  threadApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyThread(state) {
    state.loading = false;
    state.data = [];
  },
};
