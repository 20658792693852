export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/view/dashboard"),
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("@/view/partner/Partners.vue"),
  },
  {
    path: "partner-info/:id",
    name: "partner-info/:id",
    component: () => import("@/view/partner/PartnerInfo.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/view/contact/Contact.vue"),
  },
  {
    path: "purchase",
    name: "purchase",
    children: [
      {
        path: "deals",
        name: "deals",
        component: () => import("@/view/deal/DealsList.vue"),
      },
      {
        path: "inspection-rapport",
        name: "inspection-rapport",
        component: () => import("@/view/deal/inspection/inspectionList.vue"),
      },
      {
        path: "transport-rapport",
        name: "transport-rapport",
        component: () => import("@/view/deal/transport/TransportList.vue"),
      },
      {
        path: "deal",
        name: "deal",
        component: () => import("@/view/deal/Deal.vue"),
      },
      {
        path: "edit-deal/:id",
        name: "edit-deal/:id",
        component: () => import("@/view/deal/Deal.vue"),
      },
      {
        path: "virtual-stock",
        name: "virtual-stock",
        component: () => import("@/view/deal/Products.vue"),
      },
      {
        path: "purchase-orders",
        name: "purchase-orders",
        component: () => import("@/view/purchase/PurchaseList.vue"),
      },
      {
        path: "purchase-order",
        name: "purchase-order",
        component: () => import("@/view/purchase/Purchase.vue"),
      },
      {
        path: "edit-purchase-order/:id",
        name: "edit-purchase-order/:id",
        component: () => import("@/view/purchase/Purchase.vue"),
      },
    ],
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/view/product/List.vue"),
  },
  {
    path: "supplier",
    name: "supplier",
    component: () => import("@/view/supplier/Supplier.vue"),
  },
  {
    path: "sale",
    name: "sale",
    children: [
      {
        path: "sale-order",
        name: "sale-order",
        component: () => import("@/view/sale/Sale.vue"),
      },
      {
        path: "edit-sale-order/:id",
        name: "edit-sale-order/:id",
        component: () => import("@/view/sale/Sale.vue"),
      },
      {
        path: "edit-invoice-order/:id",
        name: "edit-invoice-order/:id",
        component: () => import("@/view/sale/Invoice.vue"),
      },
      {
        path: "listing/:type",
        name: "listing/:type",
        component: () => import("@/view/sale/List.vue"),
      },
      {
        path: ":type",
        name: ":type",
        component: () => import("@/view/sale/Sale-old.vue"),
      },
      {
        path: "edit-sale/:type/:id",
        name: "edit-sale/:type/:id",
        component: () => import("@/view/sale/Sale-old.vue"),
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("@/view/sale/order/List.vue"),
      },
      {
        path: "order",
        name: "order",
        component: () => import("@/view/sale/order/Order.vue"),
      },
      {
        path: "edit-order/:id",
        name: "edit-order/:id",
        component: () => import("@/view/sale/order/Order.vue"),
      },
    ],
  },
  {
    path: "finances-setting",
    name: "finances-setting",
    children: [
      {
        path: "cash",
        name: "cash",
        component: () => import("@/view/finances/Cash.vue"),
      },
      {
        path: "bank",
        name: "bank",
        component: () => import("@/view/finances/Bank.vue"),
      },
      {
        path: "accounts",
        name: "accounts",
        component: () => import("@/view/finances/Accounts.vue"),
      },
      {
        path: "bank-operations-type",
        name: "bank-operations-type",
        component: () => import("@/view/finances/ExpenseType.vue"),
      },
      {
        path: "cash-operations-type",
        name: "cash-operations-type",
        component: () => import("@/view/finances/CashOperationType.vue"),
      },
      {
        path: "leasing-operations-type",
        name: "leasing-operations-type",
        component: () => import("@/view/sale/order/List.vue"),
      },
      {
        path: "payment-method",
        name: "payment-method",
        component: () => import("@/view/finances/PaymentMethod.vue"),
      },
      {
        path: "payment-term-options",
        name: "payment-term-options",
        component: () => import("@/view/finances/PaymentTermOption.vue"),
      },
    ],
  },
  {
    path: "finances",
    name: "finances",
    children: [
      {
        path: "bank-operations",
        name: "bank-operations",
        component: () => import("@/view/finances/BankOperations.vue"),
      },
      {
        path: "expenses-operations",
        name: "expenses-operations",
        component: () => import("@/view/finances/ExpenseOperation.vue"),
      },
      {
        path: "cash-operations",
        name: "cash-operations",
        component: () => import("@/view/finances/CashOperations.vue"),
      },
      {
        path: "leasing-operations",
        name: "leasing-operations",
        component: () => import("@/view/finances/CashOperations.vue"),
      },
    ],
  },
  {
    path: "settings",
    name: "settings",
    children: [
      {
        path: "thread",
        name: "thread",
        component: () => import("@/view/settings/Thread.vue"),
      },
      {
        path: "transport-method",
        name: "transport-method",
        component: () => import("@/view/settings/TransportMethod.vue"),
      },
      {
        path: "grade",
        name: "grade",
        component: () => import("@/view/settings/Grade.vue"),
      },
      {
        path: "inspectionSOW",
        name: "inspectionSOW",
        component: () => import("@/view/settings/InspectionSOW.vue"),
      },
      {
        path: "hscode",
        name: "hscode",
        component: () => import("@/view/settings/HSCode.vue"),
      },
      {
        path: "incoterm",
        name: "incoterm",
        component: () => import("@/view/settings/Incoterm.vue"),
      },
      {
        path: "range",
        name: "range",
        component: () => import("@/view/settings/Range.vue"),
      },
      {
        path: "psl",
        name: "psl",
        component: () => import("@/view/settings/Psl.vue"),
      },
      {
        path: "units",
        name: "units",
        component: () => import("@/view/settings/Units.vue"),
      },
      {
        path: "size",
        name: "size",
        component: () => import("@/view/settings/Size.vue"),
      },
      {
        path: "weight",
        name: "weight",
        component: () => import("@/view/settings/Weight.vue"),
      },
      {
        path: "category",
        name: "category",
        component: () => import("@/view/settings/Category.vue"),
      },
      {
        path: "activities",
        name: "activities",
        component: () => import("@/view/settings/Activities.vue"),
      },
      {
        path: "lineproduct",
        name: "lineproduct",
        component: () => import("@/view/settings/ProductLine.vue"),
      },
      {
        path: "currency",
        name: "currency",
        component: () => import("@/view/settings/Currency.vue"),
      },
    ],
  },
  {
    path: "/global-settings",
    name: "global-settings",
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ "@/view/globalSettings/Settings.vue"
      ),
    children: [
      {
        path: "profile-settings",
        name: "profile-settings",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/view/globalSettings/overview/Profile.vue"
          ),
      },
      {
        path: "profile-settings/profile",
        name: "set-profile",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/view/globalSettings/overview/Profile.vue"
          ),
      },
      {
        path: "profile-settings/pdf",
        name: "pdf-setting",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/view/globalSettings/overview/PDFSettings.vue"
          ),
      },
      {
        path: "profile-settings/account",
        name: "set-account",
        component: () =>
          import(
            /* webpackChunkName: "Account" */ "@/view/globalSettings/overview/Account.vue"
          ),
      },
      {
        path: "profile-settings/password",
        name: "set-password",
        component: () =>
          import(
            /* webpackChunkName: "Password" */ "@/view/globalSettings/overview/Password.vue"
          ),
      },
      {
        path: "profile-settings/social",
        name: "set-social",
        component: () =>
          import(
            /* webpackChunkName: "Social" */ "@/view/globalSettings/overview/SocialProfile.vue"
          ),
      },
      {
        path: "profile-settings/notification",
        name: "set-notification",
        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/view/globalSettings/overview/Notification.vue"
          ),
      },
    ],
  },
];
