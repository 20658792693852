export default {
  purchaseApiAddBeginBegin(state) {
    state.loading = true;
  },
  purchaseApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiReadBegin(state) {
    state.loading = true;
  },

  purchaseApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiUpdateBegin(state) {
    state.loading = true;
  },

  purchaseApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiDeleteBegin(state) {
    state.loading = true;
  },

  purchaseApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiSingleDataBegin(state) {
    state.loading = true;
  },

  purchaseApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchaseApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiUploadBegin(state) {
    state.fileLoading = true;
  },

  purchaseApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  purchaseApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  purchaseApiSearchBegin(state) {
    state.loading = true;
  },

  purchaseApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchaseApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchaseApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.purchaseId = data.id;
    state.purchaseNumber = data.number;
    state.data = data;
    state.apiResponse = data;
  },
  purchaseApiGenerateSuccess(state, data) {
    state.loading = false;
    state.purchaseNumber = data.message;
  },
  purchaseApiReadSuccess(state, data) {
    state.loading = false;
    state.apiResponse = data;
    state.purchaseId = data.id;
  },
  purchaseApiUpdateBeginSuccess(state) {
    state.loading = false;
  },
  purchaseApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.apiResponse.data.findIndex((x) => x.id == id);
    state.apiResponse.data.splice(foundIndex, 1);
  },
  emptyPurchaseData(state) {
    state.loading = false;
    state.data = [];
    state.purchaseId = null;
  },
};
