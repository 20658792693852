import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "product added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async productSubmitData({ commit }, data) {
    try {
      await commit("productApiAddBeginBegin");
      const response = await DataService.post("api/Product", data.formState);
      await commit("productApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("productApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async getProduct({ commit }, productId) {
    try {
      await commit("productApiReadBegin");

      const query = await DataService.get(`api/Product/${productId}`);
      await commit("productApiReadSuccess", query.data);
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },

  async productGetAllData({ commit }) {
    try {
      await commit("productApiReadBegin");

      const query = await DataService.get(
        `api/Product?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("productApiReadSuccess", query.data);
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },
  async productPurchaseProductData({ commit }, product) {
    try {
      await commit("productApiReadBegin");

      const query = await DataService.get(
        `api/Purchase/GetPurchaseProductsByIdPurchaseAndIdProduct?PurchaseId=${product.purchaseId}&ProductId=${product.productId}`
      );
      await commit("productApiReadSuccess", query.data);
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },

  async productGetPagedData({ commit }, pagination) {
    try {
      await commit("productApiReadBegin");

      const response = await DataService.get(
        `api/Product?OrderBy=name&ProductName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("productApiReadSuccess", response);
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },

  async productInfo({ commit }) {
    try {
      await commit("productApiReadBegin");
      const query = await DataService.get(
        `api/Product?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("productApiReadSuccess", query.data);
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },

  async productUpdateData({ commit }, data) {
    try {
      await commit("productApiUpdateBegin");
      const response = await DataService.put(
        `api/Product/${data.formState.id}`,
        data.formState
      );
      await commit("productApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("productApiUpdateErr", err);

      notificationError(err);
    }
  },

  async productApiDataSearch({ commit }, searchItem) {
    try {
      await commit("productApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("productApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("productApiReadSuccess", query.data);
        } catch (err) {
          await commit("productApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("productApiReadErr", err);
    }
  },

  async productApiDataDelete({ commit }, { id }) {
    try {
      await commit("productApiDeleteBegin");
      await DataService.delete(`/api/Product/${id}`);
      await commit("productApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("productApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptySingelProduct({ commit }) {
    await commit("emptySingelProduct");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
