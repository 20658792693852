import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "BankDetails added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: null,
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async bankDetailsSubmitData({ commit }, data) {
    try {
      await commit("bankDetailsApiAddBeginBegin");
      const response = await DataService.post(
        "api/PartenaireDetailsBanciare",
        data.bankState
      );
      await commit("bankDetailsApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("bankDetailsApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async bankDetailsGetAllData({ commit }, pagination) {
    try {
      await commit("bankDetailsApiReadBegin");

      //OrderBy=Name\u0020${pagination.OrderBy}&
      const query = await DataService.get(
        `api/Partenaire?PartenaireName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("bankDetailsApiReadSuccess", query.data);
    } catch (err) {
      await commit("bankDetailsApiReadErr", err);
    }
  },
  async bankDetailsByPartner({ commit }, partenaire) {
    try {
      await commit("bankDetailsApiReadBegin");

      //OrderBy=Name\u0020${pagination.OrderBy}&
      const query = await DataService.get(
        `api/PartenaireDetailsBanciare/GetByIdPartenaire?idPartenaire=${partenaire}`
      );
      await commit("bankDetailsApiReadSuccess", query.data);
    } catch (err) {
      await commit("bankDetailsApiReadErr", err);
    }
  },

  async bankDetailsInfo({ commit }) {
    try {
      await commit("bankDetailsApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("bankDetailsApiReadSuccess", query.data);
    } catch (err) {
      await commit("bankDetailsApiReadErr", err);
    }
  },

  async bankDetailsUpdateData({ commit }, data) {
    try {
      await commit("bankDetailsApiUpdateBegin");
      const response = await DataService.put(
        `api/PartenaireDetailsBanciare/${data.bankState.id}`,
        data.bankState
      );
      await commit("bankDetailsApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("bankDetailsApiUpdateErr", err);

      notificationError(err);
    }
  },

  async bankDetailsApiDataSearch({ commit }, searchItem) {
    try {
      await commit("bankDetailsApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("bankDetailsApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("bankDetailsApiReadSuccess", query.data);
        } catch (err) {
          await commit("bankDetailsApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("bankDetailsApiReadErr", err);
    }
  },

  async bankDetailsApiDataDelete({ commit }, data) {
    try {
      await commit("bankDetailsApiDeleteBegin");
      await DataService.delete(`/api/PartenaireDetailsBanciare/${data.id}`);
      await commit("bankDetailsApiDeleteSuccess", data.id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("bankDetailsApiDeleteErr", err);

      notificationError(err);
    }
  },

  async emptyBankDetails({ commit }) {
    await commit("emptyBankDetails");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
