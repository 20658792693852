export default {
  bankOperationApiAddBeginBegin(state) {
    state.loading = true;
  },
  bankOperationApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiReadBegin(state) {
    state.loading = true;
  },

  bankOperationApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiUpdateBegin(state) {
    state.loading = true;
  },

  bankOperationApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiDeleteBegin(state) {
    state.loading = true;
  },

  bankOperationApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiSingleDataBegin(state) {
    state.loading = true;
  },

  bankOperationApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankOperationApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiUploadBegin(state) {
    state.fileLoading = true;
  },

  bankOperationApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  bankOperationApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  bankOperationApiSearchBegin(state) {
    state.loading = true;
  },

  bankOperationApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankOperationApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankOperationApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  bankOperationApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  bankOperationTotlaApiReadSuccess(state, data) {
    state.totalIncome = data.total.totalIncome;
    state.totalOutcome = data.total.totalOutcome;
    state.allTotalIncome = data.allTotal.totalIncome;
    state.allTotalOutcome = data.allTotal.totalOutcome;
  },
  bankOperationApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  bankOperationApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyBank(state) {
    state.loading = false;
    state.data = [];
  },
};
