export default {
  fileApiAddBeginBegin(state) {
    state.loading = true;
  },
  fileApiAddBeginErr(state, err) {
    state.loading = false;
    let file = {
      uid: Date.now().toString(36) + Math.random().toString(36).substring(2),
      name: err.get("FileName"),
      fileType: err.get("FileType"),
      status: "error",
      success: false,
      response: "Server Error 500", // custom error message to show
    };
    state.data.push(file);
  },

  fileApiReadBegin(state) {
    state.loading = true;
  },

  fileApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fileApiUpdateBegin(state) {
    state.loading = true;
  },

  fileApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fileApiDeleteBegin(state) {
    state.loading = true;
  },

  fileApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fileApiSingleDataBegin(state) {
    state.loading = true;
  },

  fileApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  fileApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fileApiUploadBegin(state) {
    state.fileLoading = true;
  },

  fileApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  fileApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  fileApiSearchBegin(state) {
    state.loading = true;
  },

  fileApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  fileApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  fileApiAddBeginSuccess(state, data) {
    state.loading = data;
    let file = {
      id: data.fileId,
      uid: data.fileId,
      ItemId: data.fileId,
      name: data.filename,
      fileName: data.filename,
      fileType: data.fileType,
      url: `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${data.fileId}`,
      success: true,
      status: "done",
    };
    state.data.push(file);
  },
  fileApiAddParentBeginSuccess(state, data) {
    state.loading = data;
    let file = {
      id: data.fileId,
      uid: data.fileId,
      ItemId: data.fileId,
      name: data.filename,
      fileName: data.filename,
      fileType: data.fileType,
      url: `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${data.fileId}`,
      success: true,
      status: "done",
    };
    state.parentFiles.push(file);
  },
  fileApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.fileId = data.id;
  },
  fileApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  fileApiDeleteSuccess(state, uid) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.uid == uid);
    state.data.splice(foundIndex, 1);
  },
  fileApiDeleteParentSuccess(state, uid) {
    state.loading = false;
    var foundIndex = state.parentFiles.findIndex((x) => x.uid == uid);
    state.parentFiles.splice(foundIndex, 1);
  },
  emptyFileData(state) {
    state.loading = false;
    state.data = [];
    state.fileId = null;
  },
  setIntialFiles(state, files) {
    state.loading = false;
    state.data = files;
    state.fileId = null;
  },
  setParentlFiles(state, files) {
    state.loading = false;
    state.parentFiles = files;
    state.fileId = null;
  },
};
