export default {
  purchasePaymentTermsApiAddBeginBegin(state) {
    state.loading = true;
  },
  purchasePaymentTermsApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiReadBegin(state) {
    state.loading = true;
  },
  purchasePaymentTermsFormApiReadBegin(state) {
    state.loading = true;
  },
  purchasePaymentTermsFormApiReadSucess(state) {
    state.loading = true;
  },

  purchasePaymentTermsApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiUpdateBegin(state) {
    state.loading = true;
  },

  purchasePaymentTermsApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiDeleteBegin(state) {
    state.loading = true;
  },

  purchasePaymentTermsApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiSingleDataBegin(state) {
    state.loading = true;
  },

  purchasePaymentTermsApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchasePaymentTermsApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiUploadBegin(state) {
    state.fileLoading = true;
  },

  purchasePaymentTermsApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  purchasePaymentTermsApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  purchasePaymentTermsApiSearchBegin(state) {
    state.loading = true;
  },

  purchasePaymentTermsApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchasePaymentTermsApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  purchasePaymentTermsApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  purchasePaymentTermsApiUpdateSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex].files = data.files;
  },
  purchasePaymentTermsApiAddBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.index == data.index);
    delete data.index;
    state.data[foundIndex] = data;
  },
  purchasePaymentTermsApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.productId == id);
    state.data.splice(foundIndex, 1);
  },
  emptyPaymentTermsData(state) {
    state.loading = false;
    state.data = [];
  },
};
