import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "unit added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async unitSubmitData({ commit }, data) {
    try {
      await commit("unitApiAddBeginBegin");
      const response = await DataService.post(
        "api/UnitOfMeasure",
        data.formState
      );
      await commit("unitApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("unitApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async unitGetPagedData({ commit }, pagination) {
    try {
      await commit("unitApiReadBegin");

      const query = await DataService.get(
        `api/UnitOfMeasure?OrderBy=name&UnitName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("unitApiReadSuccess", query);
    } catch (err) {
      await commit("unitApiReadErr", err);
    }
  },

  async unitGetAllData({ commit }) {
    try {
      await commit("unitApiReadBegin");

      const query = await DataService.get(
        `api/UnitOfMeasure?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("unitApiReadSuccess", query.data);
    } catch (err) {
      await commit("unitApiReadErr", err);
    }
  },

  async unitInfo({ commit }) {
    try {
      await commit("unitApiReadBegin");
      const query = await DataService.get(
        `api/UnitOfMeasure?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("unitApiReadSuccess", query.data);
    } catch (err) {
      await commit("unitApiReadErr", err);
    }
  },

  async unitUpdateData({ commit }, data) {
    try {
      await commit("unitApiUpdateBegin");
      const response = await DataService.put(
        `api/UnitOfMeasure/${data.formState.id}`,
        data.formState
      );
      await commit("unitApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("unitApiUpdateErr", err);

      notificationError(err);
    }
  },

  async unitApiDataSearch({ commit }, searchItem) {
    try {
      await commit("unitApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("unitApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("unitApiReadSuccess", query.data);
        } catch (err) {
          await commit("unitApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("unitApiReadErr", err);
    }
  },

  async unitApiDataDelete({ commit }, { id }) {
    try {
      await commit("unitApiDeleteBegin");
      await DataService.delete(`/api/UnitOfMeasure/${id}`);
      await commit("unitApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("unitApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyUnit({ commit }) {
    await commit("emptyUnit");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
