export default {
  paymentMethodApiAddBeginBegin(state) {
    state.loading = true;
  },
  paymentMethodApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiReadBegin(state) {
    state.loading = true;
  },

  paymentMethodApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiUpdateBegin(state) {
    state.loading = true;
  },

  paymentMethodApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiDeleteBegin(state) {
    state.loading = true;
  },

  paymentMethodApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiSingleDataBegin(state) {
    state.loading = true;
  },

  paymentMethodApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  paymentMethodApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiUploadBegin(state) {
    state.fileLoading = true;
  },

  paymentMethodApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  paymentMethodApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  paymentMethodApiSearchBegin(state) {
    state.loading = true;
  },

  paymentMethodApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  paymentMethodApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentMethodApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  paymentMethodApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  paymentMethodApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  paymentMethodApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },

  emptyGrade(state) {
    state.loading = false;
    state.data = [];
  },
};
