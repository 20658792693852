import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Expense Operation added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  totalPaginated: 0,
  totalNotPaginated: 0,
  error: null,
});

const actions = {
  async expenseOperationSubmitData({ commit, dispatch }, data) {
    try {
      await commit("expenseOperationApiAddBeginBegin");
      const response = await DataService.post(
        "api/Expense",
        data.formState
      );
      await commit("expenseOperationApiAddBeginSuccess", response.data);
      dispatch("expenseOperationGetTotal", data.pagination);
      addNotificationSuccess();
    } catch (err) {
      await commit("expenseOperationApiAddBeginErr", err);
      notificationError(err);
    }
  },

  async expenseOperationGetPagedData({ commit }, pagination) {
    console.log("pagination", pagination);
    try {
      await commit("expenseOperationApiReadBegin");
      const query = await DataService.get(
        `api/Expense?addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.OrderBy}&OrderByDirection=${pagination.sorter.order}&dateFrom=${pagination.filters.dateFrom}&dateTo=${pagination.filters.dateTo}&BankOperationTypeId=${pagination.filters.bankOperationTypeId}&partnerId=${pagination.filters.partnerId}&receiptType=${pagination.filters.receiptType}`
      );
      await commit("expenseOperationApiReadSuccess", query);
    } catch (err) {
      await commit("expenseOperationApiReadErr", err);
    }
  },
  async updateExpenseFiles({ commit }, data) {
    await commit("updateExpenseFiles", data);
  },
  async expenseOperationGetAllData({ commit }) {
    try {
      await commit("expenseOperationApiReadBegin");

      const query = await DataService.get(
        `api/Expense?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("expenseOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("expenseOperationApiReadErr", err);
    }
  },
  async expenseOperationGetTotal({ commit }, pagination) {
    try {
      await commit("expenseOperationApiReadBegin");

      const query = await DataService.get(
        `api/Expense/GetTotal?PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.OrderBy}&OrderByDirection=${pagination.sorter.order}&dateFrom=${pagination.filters.dateFrom}&dateTo=${pagination.filters.dateTo}&BankOperationTypeId=${pagination.filters.bankOperationTypeId}&partnerId=${pagination.filters.partnerId}&receiptType=${pagination.filters.receiptType}`
      );
   
      await commit("expenseOperationTotlaApiReadSuccess", query.data );
    } catch (err) {
      await commit("expenseOperationApiReadErr", err);
    }
  },

  async expenseOperationInfo({ commit }) {
    try {
      await commit("expenseOperationApiReadBegin");
      const query = await DataService.get(
        `api/Expense?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("expenseOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("expenseOperationApiReadErr", err);
    }
  },

  async expenseOperationUpdateData({ commit, dispatch }, data) {
    try {
      await commit("expenseOperationApiUpdateBegin");
      const response = await DataService.put(
        `api/Expense/${data.formState.id}`,
        data.formState
      );
      await commit("expenseOperationApiUpdateBeginSuccess", response.data);
      dispatch("expenseOperationGetTotal",  data.pagination );
      updateNotificationSuccess();
    } catch (err) {
      await commit("expenseOperationApiUpdateErr", err);

      notificationError(err);
    }
  },
  async expenseOperationValidation({ commit }, expenseOperation) {
    try {
      await commit("expenseOperationApiUpdateBegin");
      const response = await DataService.put(
        `api/Expense/Validate?id=${expenseOperation.id}`);
      if (response.succeeded) {   
        await commit("expenseOperationApiValidateBeginSuccess", expenseOperation);
      }
      updateNotificationSuccess();
    } catch (err) {
      await commit("expenseOperationApiUpdateErr", err);

      notificationError(err);
    }
  },
  async expenseOperationApiDataSearch({ commit }, searchItem) {
    try {
      await commit("expenseOperationApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("expenseOperationApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("expenseOperationApiReadSuccess", query.data);
        } catch (err) {
          await commit("expenseOperationApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("expenseOperationApiReadErr", err);
    }
  },

  async expenseOperationApiDataDelete({ commit, dispatch }, request) {
    try {
      await commit("expenseOperationApiDeleteBegin");
      await DataService.delete(`/api/Expense/${request.id}`);
      await commit("expenseOperationApiDeleteSuccess", request.id);
      dispatch("expenseOperationGetTotal", request.pagination);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("expenseOperationApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyExpenseOperation({ commit }) {
    await commit("emptyExpenseOperation");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
