import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "account added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async accountSubmitData({ commit }, data) {
    try {
      await commit("accountApiAddBeginBegin");
      const response = await DataService.post(
        "api/BankAccount",
        data.formState
      );
      await commit("accountApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("accountApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async accountGetPagedData({ commit }, pagination) {
    try {
      await commit("accountApiReadBegin");

      const query = await DataService.get(
        `api/BankAccount/GetDto?OrderBy=name&name=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("accountApiReadSuccess", query);
    } catch (err) {
      await commit("accountApiReadErr", err);
    }
  },

  async accountGetAllData({ commit }) {
    try {
      await commit("accountApiReadBegin");

      const query = await DataService.get(
        `api/BankAccount?OrderBy=name&PageAccount=10000000`
      );
      await commit("accountApiReadSuccess", query.data);
    } catch (err) {
      await commit("accountApiReadErr", err);
    }
  },

  async accountInfo({ commit }) {
    try {
      await commit("accountApiReadBegin");
      const query = await DataService.get(
        `api/BankAccount?OrderBy=name&Fields=id%2Cname%2CsizeId&PageSize=10000000`
      );
      await commit("accountApiReadSuccess", query.data);
    } catch (err) {
      await commit("accountApiReadErr", err);
    }
  },

  async accountUpdateData({ commit }, data) {
    try {
      await commit("accountApiUpdateBegin");
      const response = await DataService.put(
        `api/BankAccount/${data.formState.id}`,
        data.formState
      );
      await commit("accountApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("accountApiUpdateErr", err);

      notificationError(err);
    }
  },

  async accountApiDataSearch({ commit }, searchItem) {
    try {
      await commit("accountApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("accountApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("accountApiReadSuccess", query.data);
        } catch (err) {
          await commit("accountApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("accountApiReadErr", err);
    }
  },

  async accountApiDataDelete({ commit }, id) {
    try {
      await commit("accountApiDeleteBegin");
      await DataService.delete(`/api/BankAccount/${id}`);
      await commit("accountApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("accountApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
