export default {
  accountApiAddBeginBegin(state) {
    state.loading = true;
  },
  accountApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiReadBegin(state) {
    state.loading = true;
  },

  accountApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiUpdateBegin(state) {
    state.loading = true;
  },

  accountApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiDeleteBegin(state) {
    state.loading = true;
  },

  accountApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiSingleDataBegin(state) {
    state.loading = true;
  },

  accountApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  accountApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiUploadBegin(state) {
    state.fileLoading = true;
  },

  accountApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  accountApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  accountApiSearchBegin(state) {
    state.loading = true;
  },

  accountApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  accountApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  accountApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  accountApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  accountApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  accountApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
