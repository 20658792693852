export default {
  productApiAddBeginBegin(state) {
    state.loading = true;
  },
  productApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiReadBegin(state) {
    state.loading = true;
  },

  productApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiUpdateBegin(state) {
    state.loading = true;
  },

  productApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiDeleteBegin(state) {
    state.loading = true;
  },

  productApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiSingleDataBegin(state) {
    state.loading = true;
  },

  productApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiUploadBegin(state) {
    state.fileLoading = true;
  },

  productApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  productApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  productApiSearchBegin(state) {
    state.loading = true;
  },

  productApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  productApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  productApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  productApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },

  emptySingelProduct(state) {
    state.loading = false;
    state.data = [];
  },
};
