import app from "./config/configApp";
import i18n from "./config/i18n";
import router from "./routes/protectedRoute";
import store from "@/vuex/store";
import permissionsPlugin from './plugins/permissions';
import CKEditor from '@ckeditor/ckeditor5-vue';

import "./static/css/style.css";
// Vue 3rd party plugins
import "@/core/plugins/ant-design";
import "@/core/plugins/fonts";
import "@/core/components/custom";
import "@/core/components/style";

app.config.productionTip = false;
app.use(CKEditor);
app.use(i18n);
app.use(store);
app.use(permissionsPlugin, store);
app.use(router);

app.mount("#app");
