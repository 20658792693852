export default {
  dealApiAddBeginBegin(state) {
    state.loading = true;
  },
  dealApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiReadBegin(state) {
    state.loading = true;
  },

  dealApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiUpdateBegin(state) {
    state.loading = true;
  },
  dealApiGenerateSuccess(state, data) {
    state.loading = false;
    state.dealNumber = data;
  },
  dealApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiDeleteBegin(state) {
    state.loading = true;
  },

  dealApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiSingleDataBegin(state) {
    state.loading = true;
  },

  dealApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  dealApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiUploadBegin(state) {
    state.fileLoading = true;
  },

  dealApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  dealApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  dealApiSearchBegin(state) {
    state.loading = true;
  },

  dealApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  dealApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  dealApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.dealId = data.id;
    state.data = data;
  },
  dealApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.dealId = data.id;
  },
  dealApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  dealApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
  emptyDealData(state) {
    state.loading = false;
    state.data = [];
    state.dealId = null;
  },
};
