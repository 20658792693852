export default {
  rangeApiAddBeginBegin(state) {
    state.loading = true;
  },
  rangeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiReadBegin(state) {
    state.loading = true;
  },

  rangeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiUpdateBegin(state) {
    state.loading = true;
  },

  rangeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiDeleteBegin(state) {
    state.loading = true;
  },

  rangeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiSingleDataBegin(state) {
    state.loading = true;
  },

  rangeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  rangeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  rangeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  rangeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  rangeApiSearchBegin(state) {
    state.loading = true;
  },

  rangeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  rangeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  rangeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  rangeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  rangeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  rangeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyRange(state) {
    state.loading = false;
    state.data = [];
  },
};
