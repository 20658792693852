export default {
  currencyApiAddBeginBegin(state) {
    state.loading = true;
  },
  currencyApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiReadBegin(state) {
    state.loading = true;
  },

  currencyApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiUpdateBegin(state) {
    state.loading = true;
  },

  currencyApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiDeleteBegin(state) {
    state.loading = true;
  },

  currencyApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiSingleDataBegin(state) {
    state.loading = true;
  },

  currencyApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  currencyApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiUploadBegin(state) {
    state.fileLoading = true;
  },

  currencyApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  currencyApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  currencyApiSearchBegin(state) {
    state.loading = true;
  },

  currencyApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  currencyApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  currencyApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  currencyApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  currencyApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  currencyApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyCurrency(state) {
    state.loading = false;
    state.data = [];
  },
};
