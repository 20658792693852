import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "weight added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async weightSubmitData({ commit }, data) {
    try {
      await commit("weightApiAddBeginBegin");
      const response = await DataService.post("api/Weight", data.formState);
      await commit("weightApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("weightApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async weightGetPagedData({ commit }, pagination) {
    try {
      await commit("weightApiReadBegin");

      const query = await DataService.get(
        `api/Weight?OrderBy=SizeId&WeightName=${pagination.searchValue}&SizeId=${pagination.searchSizeId}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("weightApiReadSuccess", query);
    } catch (err) {
      await commit("weightApiReadErr", err);
    }
  },

  async weightGetAllData({ commit }) {
    try {
      await commit("weightApiReadBegin");

      const query = await DataService.get(
        `api/Weight?OrderBy=name&PageWeight=10000000`
      );
      await commit("weightApiReadSuccess", query.data);
    } catch (err) {
      await commit("weightApiReadErr", err);
    }
  },

  async weightInfo({ commit }) {
    try {
      await commit("weightApiReadBegin");
      const query = await DataService.get(
        `api/Weight?OrderBy=name&Fields=id%2Cname%2CsizeId&PageSize=10000000`
      );
      await commit("weightApiReadSuccess", query.data);
    } catch (err) {
      await commit("weightApiReadErr", err);
    }
  },

  async weightUpdateData({ commit }, data) {
    try {
      await commit("weightApiUpdateBegin");
      const response = await DataService.put(
        `api/Weight/${data.formState.id}`,
        data.formState
      );
      await commit("weightApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("weightApiUpdateErr", err);

      notificationError(err);
    }
  },

  async weightApiDataSearch({ commit }, searchItem) {
    try {
      await commit("weightApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("weightApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("weightApiReadSuccess", query.data);
        } catch (err) {
          await commit("weightApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("weightApiReadErr", err);
    }
  },

  async weightApiDataDelete({ commit }, id) {
    try {
      await commit("weightApiDeleteBegin");
      await DataService.delete(`/api/Weight/${id}`);
      await commit("weightApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("weightApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
