import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Auxiliary Charge Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Auxiliary Charge deleted",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Auxiliary Charge updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async auxiliaryChargeGetData({ commit }, data) {
    try {
      await commit("auxiliaryChargeApiReadBegin");
      const query = await DataService.get(
        `/api/AuxiliaryCharge?${data}&PageSize=10000`
        );
        await commit("auxiliaryChargeApiReadSuccess", query.data);
      } catch (err) {
        await commit("auxiliaryChargeApiReadErr", err);
      }
    },
  async auxiliaryChargeGetFromDeal({ commit }, data) {
    try {
      await commit("auxiliaryChargeApiReadBegin");
      const query = await DataService.get(
        `/api/AuxiliaryCharge?${data.target}&PageSize=10000`
      );
      await commit("auxiliaryChargeApiReadSuccessFromDeal", { result: query.data, parentType: data.parentType});
    } catch (err) {
      await commit("auxiliaryChargeApiReadErr", err);
    }
  },
  async auxiliaryChargeSubmitData({ commit }, data) {
    try {
      await commit("auxiliaryChargeApiAddBeginBegin");
      const response = await DataService.post("/api/AuxiliaryCharge", data);
      response.data.index = data.index;
      await commit("auxiliaryChargeApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("auxiliaryChargeApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async auxiliaryChargeUpdateData({ commit }, data) {
    try {
      await commit("auxiliaryChargeApiUpdateBegin");
      await DataService.put("/api/AuxiliaryCharge/" + data.id, data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("auxiliaryChargeApiUpdateSuccess", err);

      notificationError(err);
    }
  },

  async auxiliaryChargeApiDataDelete({ commit }, data) {
    try {
      await commit("auxiliaryChargeApiDeleteBegin");
      await DataService.delete("/api/AuxiliaryCharge/" + data.id);
      await commit("auxiliaryChargeApiDeleteSuccess", data.id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("auxiliaryChargeApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyAuxiliaryChargeData({ commit }) {
    await commit("emptyAuxiliaryChargeData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
 