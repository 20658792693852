// plugins/permissions.js
export default {
    install: (app, store) => {
        app.config.globalProperties.$userHasPermission = function (permission) {
            const moduleState = store.state["auth"];
            if (moduleState && moduleState.ConnectedUserPermison) {
                return moduleState.ConnectedUserPermison.includes(permission);
            }
            return false;
        };
    },
};
