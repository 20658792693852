export default {
  contactApiAddBeginBegin(state) {
    state.loading = true;
  },
  contactApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiReadBegin(state) {
    state.loading = true;
  },

  contactApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiUpdateBegin(state) {
    state.loading = true;
  },

  contactApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiDeleteBegin(state) {
    state.loading = true;
  },

  contactApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiSingleDataBegin(state) {
    state.loading = true;
  },

  contactApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  contactApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiUploadBegin(state) {
    state.fileLoading = true;
  },

  contactApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  contactApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  contactApiSearchBegin(state) {
    state.loading = true;
  },

  contactApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  contactApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.push(data);
  },
  contactApiReadSuccess(state, data) {
    console.log("data", data);
    state.loading = false;
    state.data = data;
  },
  contactApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  contactApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
// Contact Permissions
  contactPermissionApiReadBegin(state) {
    state.loading = true;
  },
  contactGetllPermissionApiSuccess(state, data) {
    state.loading = false;
    const groupedData = data.reduce((result, item) => {
      const resourceName = item.name.split(".")[1]; // Get the second element in the name
      if (!result[resourceName]) {
        result[resourceName] = [];
      }
      result[resourceName].push(item);
      return result;
    }, {});

    console.log("grouped data", groupedData);

    // const transformedData = Object.keys(groupedData).map(action => ({
    //   label: action,
    //   options: groupedData[action].map(item => ({
    //     label: item.description,
    //     value: item.id
    //   }))
    // }));
    
    state.dataPermisson = groupedData
  },
  contactUserPermissionApiSuccess(state, data) {
    state.loading = false;
    state.userPermission = data.map(item => item.permissionId);
  },

  contactPermissionApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  contactPermissionApiUpdateBegin(state) {
    state.loading = true;
  },

  contactPermissionApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  emptyUsersData(state) {
    state.loading = false;
    state.data = [];
    state.dealId = null;
  },
  emptyUserPermission(state) {
    state.userPermission = []
  },
};
