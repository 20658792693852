export default {
  bankDetailsApiAddBeginBegin(state) {
    state.loading = true;
  },
  bankDetailsApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiReadBegin(state) {
    state.loading = true;
  },

  bankDetailsApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiUpdateBegin(state) {
    state.loading = true;
  },

  bankDetailsApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiDeleteBegin(state) {
    state.loading = true;
  },

  bankDetailsApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiSingleDataBegin(state) {
    state.loading = true;
  },

  bankDetailsApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankDetailsApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiUploadBegin(state) {
    state.fileLoading = true;
  },

  bankDetailsApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  bankDetailsApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  bankDetailsApiSearchBegin(state) {
    state.loading = true;
  },

  bankDetailsApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  bankDetailsApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  bankDetailsApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.push(data);
  },
  bankDetailsApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  bankDetailsApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  bankDetailsApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
  emptyBankDetails(state) {
    state.loading = false;
    state.data = null;
  },
};
