export default {
  paymentTermOptionApiAddBeginBegin(state) {
    state.loading = true;
  },
  paymentTermOptionApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiReadBegin(state) {
    state.loading = true;
  },

  paymentTermOptionApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiUpdateBegin(state) {
    state.loading = true;
  },

  paymentTermOptionApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiDeleteBegin(state) {
    state.loading = true;
  },

  paymentTermOptionApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiSingleDataBegin(state) {
    state.loading = true;
  },

  paymentTermOptionApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  paymentTermOptionApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiUploadBegin(state) {
    state.fileLoading = true;
  },

  paymentTermOptionApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  paymentTermOptionApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  paymentTermOptionApiSearchBegin(state) {
    state.loading = true;
  },

  paymentTermOptionApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  paymentTermOptionApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  paymentTermOptionApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  paymentTermOptionApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  paymentTermOptionApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  paymentTermOptionApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },

  emptyGrade(state) {
    state.loading = false;
    state.data = [];
  },
};
