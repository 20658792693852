import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "thread added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async threadSubmitData({ commit }, data) {
    try {
      await commit("threadApiAddBeginBegin");
      const response = await DataService.post("api/Thread", data.formState);
      await commit("threadApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("threadApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async threadGetAllData({ commit }) {
    try {
      await commit("threadApiReadBegin");

      const query = await DataService.get(
        `api/Thread?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("threadApiReadSuccess", query.data);
    } catch (err) {
      await commit("threadApiReadErr", err);
    }
  },
  async threadGetPagedData({ commit }, pagination) {
    try {
      await commit("threadApiReadBegin");

      const response = await DataService.get(
        `api/Thread?OrderBy=name&ThreadName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("threadApiReadSuccess", response);
    } catch (err) {
      await commit("threadApiReadErr", err);
    }
  },

  async threadInfo({ commit }) {
    try {
      await commit("threadApiReadBegin");
      const query = await DataService.get(
        `api/Thread?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("threadApiReadSuccess", query.data);
    } catch (err) {
      await commit("threadApiReadErr", err);
    }
  },

  async threadUpdateData({ commit }, data) {
    try {
      await commit("threadApiUpdateBegin");
      const response = await DataService.put(
        `api/Thread/${data.formState.id}`,
        data.formState
      );
      await commit("threadApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("threadApiUpdateErr", err);

      notificationError(err);
    }
  },

  async threadApiDataSearch({ commit }, searchItem) {
    try {
      await commit("threadApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("threadApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("threadApiReadSuccess", query.data);
        } catch (err) {
          await commit("threadApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("threadApiReadErr", err);
    }
  },

  async threadApiDataDelete({ commit }, { id }) {
    try {
      await commit("threadApiDeleteBegin");
      await DataService.delete(`/api/Thread/${id}`);
      await commit("threadApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("threadApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyThread({ commit }) {
    await commit("emptyThread");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
