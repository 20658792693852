import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "currency added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async currencySubmitData({ commit }, data) {
    try {
      await commit("currencyApiAddBeginBegin");
      const response = await DataService.post("api/Currency", data.formState);
      await commit("currencyApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("currencyApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async currencyGetPagedData({ commit }, pagination) {
    try {
      await commit("currencyApiReadBegin");

      const query = await DataService.get(
        `api/Currency?OrderBy=name&CurrencyName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("currencyApiReadSuccess", query);
    } catch (err) {
      await commit("currencyApiReadErr", err);
    }
  },

  async currencyGetAllData({ commit }) {
    try {
      await commit("currencyApiReadBegin");

      const query = await DataService.get(
        `api/Currency?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("currencyApiReadSuccess", query.data);
    } catch (err) {
      await commit("currencyApiReadErr", err);
    }
  },

  async currencyInfo({ commit }) {
    try {
      await commit("currencyApiReadBegin");
      const query = await DataService.get(
        `api/Currency?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("currencyApiReadSuccess", query.data);
    } catch (err) {
      await commit("currencyApiReadErr", err);
    }
  },

  async currencyUpdateData({ commit }, data) {
    try {
      await commit("currencyApiUpdateBegin");
      const response = await DataService.put(
        `api/Currency/${data.formState.id}`,
        data.formState
      );
      await commit("currencyApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("currencyApiUpdateErr", err);

      notificationError(err);
    }
  },

  async currencyApiDataSearch({ commit }, searchItem) {
    try {
      await commit("currencyApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("currencyApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("currencyApiReadSuccess", query.data);
        } catch (err) {
          await commit("currencyApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("currencyApiReadErr", err);
    }
  },

  async currencyApiDataDelete({ commit }, { id }) {
    try {
      await commit("currencyApiDeleteBegin");
      await DataService.delete(`/api/Currency/${id}`);
      await commit("currencyApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("currencyApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyCurrency({ commit }) {
    await commit("emptyCurrency");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
