import { useProduct } from "@/composable/useProduct";
export default {
  saleProductApiAddBeginBegin(state) {
    state.loading = true;
  },
  saleProductApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiReadBegin(state) {
    state.loading = true;
  },
  saleProductFormApiReadBegin(state) {
    state.loading = true;
  },
  saleProductFormApiReadSucess(state) {
    state.loading = true;
  },

  saleProductApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiUpdateBegin(state) {
    state.loading = true;
  },

  saleProductApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiDeleteBegin(state) {
    state.loading = true;
  },

  saleProductApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiSingleDataBegin(state) {
    state.loading = true;
  },

  saleProductApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  saleProductApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiUploadBegin(state) {
    state.fileLoading = true;
  },

  saleProductApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  saleProductApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  saleProductApiSearchBegin(state) {
    state.loading = true;
  },

  saleProductApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  saleProductApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleProductApiAddBeginSuccess(state, data) {
    const { formatApiProductToList } = useProduct();
    state.loading = false;
    state.data.push(formatApiProductToList(data));
  },
  saleProductApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  saleProductApiReadSuccessFromApi(state, data) {
    const { formatApiProductToList } = useProduct();
    data.forEach((saleProduct) => {
      var foundIndex = state.data.findIndex((x) => x.id == saleProduct.id);
      if (foundIndex == -1) {
        state.data.push(formatApiProductToList(saleProduct));
      }
    });
    state.loading = false;
  },
  saleProductApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  saleProductApiAddDealProductSuccess(state, data) {
    const { formatProductToList } = useProduct();
    data.forEach((saleProduct) => {
      var foundIndex = state.data.findIndex((x) => x.id == saleProduct.id);
      if (foundIndex == -1) {
        state.data.push(formatProductToList(saleProduct));
      }
    });
    state.loading = false;
  },
  updateProductFiles(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex].files = data.files;
  },
  saleProductApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.productId == id);
    state.data.splice(foundIndex, 1);
  },
  productSaleApiUpdateBeginSuccess(state, data) {
    const { getProductCondition } = useProduct();

    data.conditionName = getProductCondition(data.condition);
    data.description = data.sizeId
      ? `${data.categoryName} ${data.sizeName}, ${data.weightName}, ${data.gradeName}, ${data.threadName}, ${data.rangeName}`
      : data.note;
    data.currency = data.currencyName;
    var foundIndex = state.data.findIndex((x) => x.productId == data.productId);
    state.data[foundIndex] = data;
    state.loading = false;
  },
  emptyProductData(state) {
    state.loading = false;
    state.data = [];
  },
};
