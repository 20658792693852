import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Bank operation Type added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async expenseTypeSubmitData({ commit }, data) {
    try {
      await commit("expenseTypeApiAddBeginBegin");
      const response = await DataService.post(
        "api/BankOperationType",
        data.formState
      );
      await commit("expenseTypeApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("expenseTypeApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async expenseTypeGetPagedData({ commit }, pagination) {
    try {
      await commit("expenseTypeApiReadBegin");

      const query = await DataService.get(
        `api/BankOperationType?OrderBy=name&name=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("expenseTypeApiReadSuccess", query);
    } catch (err) {
      await commit("expenseTypeApiReadErr", err);
    }
  },

  async expenseTypeGetAllData({ commit }) {
    try {
      await commit("expenseTypeApiReadBegin");

      const query = await DataService.get(
        `api/BankOperationType?OrderBy=name&PageBankOperationType=10000000`
      );
      await commit("expenseTypeApiReadSuccess", query.data);
    } catch (err) {
      await commit("expenseTypeApiReadErr", err);
    }
  },

  async expenseTypeInfo({ commit }) {
    try {
      await commit("expenseTypeApiReadBegin");
      const query = await DataService.get(
        `api/BankOperationType?OrderBy=name&Fields=id%2Cname%2CIncomeOutcome&PageSize=10000000`
      );
      await commit("expenseTypeApiReadSuccess", query.data);
    } catch (err) {
      await commit("expenseTypeApiReadErr", err);
    }
  },

  async expenseTypeUpdateData({ commit }, data) {
    try {
      await commit("expenseTypeApiUpdateBegin");
      const response = await DataService.put(
        `api/BankOperationType/${data.formState.id}`,
        data.formState
      );
      await commit("expenseTypeApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("expenseTypeApiUpdateErr", err);

      notificationError(err);
    }
  },

  async expenseTypeApiDataSearch({ commit }, searchItem) {
    try {
      await commit("expenseTypeApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("expenseTypeApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("expenseTypeApiReadSuccess", query.data);
        } catch (err) {
          await commit("expenseTypeApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("expenseTypeApiReadErr", err);
    }
  },

  async expenseTypeApiDataDelete({ commit }, id) {
    try {
      await commit("expenseTypeApiDeleteBegin");
      await DataService.delete(`/api/BankOperationType/${id}`);
      await commit("expenseTypeApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("expenseTypeApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
