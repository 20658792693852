// i18n.js
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'en', // Default locale
    fallbackLocale: 'en',
    messages: {
        en: require('../locales/en.json'),
    },
});

export default i18n; 
