import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "transportMethod added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async transportMethodSubmitData({ commit }, data) {
    try {
      await commit("transportMethodApiAddBeginBegin");
      const response = await DataService.post("api/TransportMethod", data.formState);
      await commit("transportMethodApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("transportMethodApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async transportMethodGetAllData({ commit }) {
    try {
      await commit("transportMethodApiReadBegin");

      const query = await DataService.get(
        `api/TransportMethod?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("transportMethodApiReadSuccess", query.data);
    } catch (err) {
      await commit("transportMethodApiReadErr", err);
    }
  },
  async transportMethodGetPagedData({ commit }, pagination) {
    try {
      await commit("transportMethodApiReadBegin");

      const response = await DataService.get(
        `api/TransportMethod?OrderBy=name&TransportMethodName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("transportMethodApiReadSuccess", response);
    } catch (err) {
      await commit("transportMethodApiReadErr", err);
    }
  },

  async transportMethodInfo({ commit }) {
    try {
      await commit("transportMethodApiReadBegin");
      const query = await DataService.get(
        `api/TransportMethod?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("transportMethodApiReadSuccess", query.data);
    } catch (err) {
      await commit("transportMethodApiReadErr", err);
    }
  },

  async transportMethodUpdateData({ commit }, data) {
    try {
      await commit("transportMethodApiUpdateBegin");
      const response = await DataService.put(
        `api/TransportMethod/${data.formState.id}`,
        data.formState
      );
      await commit("transportMethodApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("transportMethodApiUpdateErr", err);

      notificationError(err);
    }
  },

  async transportMethodApiDataSearch({ commit }, searchItem) {
    try {
      await commit("transportMethodApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("transportMethodApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("transportMethodApiReadSuccess", query.data);
        } catch (err) {
          await commit("transportMethodApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("transportMethodApiReadErr", err);
    }
  },

  async transportMethodApiDataDelete({ commit }, { id }) {
    try {
      await commit("transportMethodApiDeleteBegin");
      await DataService.delete(`/api/TransportMethod/${id}`);
      await commit("transportMethodApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("transportMethodApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyTransportMethod({ commit }) {
    await commit("emptyTransportMethod");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
