import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "User added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "User updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "User deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  dataPermisson: [],
  userPermission: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async contactSubmitData({ commit }, data) {
    try {
      await commit("contactApiAddBeginBegin");
      const response = await DataService.post(
        "api/PartenaireContact/Create",
        data.formState
      );
      await commit("contactApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("contactApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async contactGetAllData({ commit }, filters) {
    try {
      await commit("contactApiReadBegin");
      const query = await DataService.get(
        `api/PartenaireContact/GetPartenaireContactDetailsByFilter?UserName=${filters.UserName}&PartenaireId=${filters.PartenaireId}`
      );
      await commit("contactApiReadSuccess", query.data);
    } catch (err) {
      await commit("contactApiReadErr", err);
    }
  },
  async contactInfo({ commit }) {
    try {
      await commit("contactApiReadBegin");
      const query = await DataService.get(
        `api/PartenaireContact/GetAll`
      );
      await commit("contactApiReadSuccess", query.data);
    } catch (err) {
      await commit("contactApiReadErr", err);
    }
  },
  async contactGetAllPermisions({ commit }) {
    try {
      await commit("contactPermissionApiReadBegin");
      const query = await DataService.get(
        `api/Permission/getAllPermission`
      );
      await commit("contactGetllPermissionApiSuccess", query.data);
    } catch (err) {
      await commit("contactPermissionApiReadErr", err);
    }
  },
  async contactUserPermisions({ commit }, userId) {
    try {
      await commit("contactPermissionApiReadBegin");
      const query = await DataService.get(
        `api/Permission/getAllUserPermissionByIdUser?userId=${userId}`
      );
      await commit("contactUserPermissionApiSuccess", query.data);
    } catch (err) {
      await commit("contactPermissionApiReadErr", err);
    }
  },

  async contactUpdateUserPermisions({ commit }, data) {
    try {
     await DataService.post(
        `api/Permission/updateUserPermission`,
        data
      );
      updateNotificationSuccess();
    } catch (err) {
      await commit("contactApiUpdateErr", err);

      notificationError(err);
    }
  },

  async contactUpdateData({ commit }, data) {
    try {
      await commit("contactApiUpdateBegin");
      const response = await DataService.put(
        `api/PartenaireContact/EditUserProfile/${data.formState.id}`,
        data.formState
      );
      await commit("contactApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("contactApiUpdateErr", err);

      notificationError(err);
    }
  },
  async contactUpdatePassword({ commit }, data) {
    try {
      await commit("contactApiUpdateBegin");
      const response = await DataService.post(
        `api/Auth/ChangePassword`,
        data.passwordFormState
      );
      await commit("contactApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("contactApiUpdateErr", err);

      notificationError(err);
    }
  },

  async contactApiDataSearch({ commit }, searchItem) {
    try {
      await commit("contactApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("contactApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("contactApiReadSuccess", query.data);
        } catch (err) {
          await commit("contactApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("contactApiReadErr", err);
    }
  },

  async contactApiDataDelete({ commit }, { id }) {
    try {
      await commit("contactApiDeleteBegin");
      await DataService.delete(`/api/PartenaireContact/Delete/${id}`);
      await commit("contactApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("contactApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyUsersData({ commit }) {
    await commit("emptyUsersData");
  },
  async emptyUserPermission({ commit }) {
    await commit("emptyUserPermission");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
