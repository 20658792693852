import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Partner added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async partnerSubmitData({ commit }, data) {
    try {
      await commit("partnerApiAddBeginBegin");
      const response = await DataService.post("api/Partenaire", data.formState);
      await commit("partnerApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("partnerApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async partnerGetAllData({ commit }, pagination) {
    try {
      await commit("partnerApiReadBegin");

      //OrderBy=Name\u0020${pagination.OrderBy}&
      const query = await DataService.get(
        `api/Partenaire?PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.orderBy}&OrderByDirection=${pagination.sorter.order}&partnerName=${pagination.filters.partnerName}&ActivityId=${pagination.filters.activityId}&countryId=${pagination.filters.countryId}`
      );
      await commit("partnerApiReadSuccess", query);
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },
  async getPartnerInfo({ commit }, id) {
    try {
      await commit("partnerApiReadBegin");
      const query = await DataService.get(`/api/Partenaire/${id}`);
      await commit("partnerApiReadSuccess", query);
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },

  async partnerInfo({ commit }) {
    try {
      await commit("partnerApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=1000000`
      );
      await commit("partnerApiReadSuccess", query.data);
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },
  async partnerWithInspectionActivityByCountry({ commit }) {
    try {
      await commit("partnerApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire/PartnerWithInspectionActivityByCountry`
      );
      if (query.data.length == 0) {
        notification.warning({
          message: "The chosen country does not currently have a partner.",
        });
      }
      await commit("partnerApiReadSuccess", query.data);
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },
  async partnerWithTransportActivityByCountry({ commit },countryId) {
    try {
      await commit("partnerApiReadBegin");
      const query = await DataService.get(
        `api/partenaire/PartnerWithTransportActivityByCountry?CountryId=${countryId}`
      );
      if (query.data.length == 0) {
        notification.warning({
          message: "The chosen country does not currently have a partner.",
        });
      }
      const objectId = "1A0E62AB-A950-41BC-2A13-08DAAAA7CF4D";
      const objectName = "Société Tunisienne de Transit du Sud (STTS)";

      const objectExists = query.data.some(
        (obj) => obj.id === objectId && obj.name === objectName
      );

      // If the object doesn't exist, push it to the array
      if (!objectExists) {
        query.data.push({
          id: objectId,
          name: objectName,
        });
      }
      await commit("partnerApiReadSuccess", query.data);
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },

  async partnerUpdateData({ commit }, data) {
    try {
      await commit("partnerApiUpdateBegin");
      const response = await DataService.put(
        `api/Partenaire/${data.formState.id}`,
        data.formState
      );
      await commit("partnerApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("partnerApiUpdateErr", err);

      notificationError(err);
    }
  },

  async partnerApiDataSearch({ commit }, searchItem) {
    try {
      await commit("partnerApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("partnerApiReadSuccess", query);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("partnerApiReadSuccess", query);
        } catch (err) {
          await commit("partnerApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("partnerApiReadErr", err);
    }
  },

  async partnerApiDataDelete({ commit }, { id }) {
    try {
      await commit("partnerApiDeleteBegin");
      await DataService.delete(`/api/Partenaire/${id}`);
      await commit("partnerApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("partnerApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyPartnerData({ commit }) {
    await commit("emptyPartnerData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
