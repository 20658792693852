import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Payment Term Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Payment Term deleted",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Payment Term updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async purchasePaymentTermsGetData({ commit }, data) {
    try {
      await commit("purchasePaymentTermsApiReadBegin");
      const query = await DataService.get(
        `/api/PaymentTerms?PurchaseId=${data}&PageSize=10000`
      );
      await commit("purchasePaymentTermsApiReadSuccess", query.data);
    } catch (err) {
      await commit("purchasePaymentTermsApiReadErr", err);
    }
  },
  async purchasePaymentTermsSubmitData({ commit }, data) {
    try {
      await commit("purchasePaymentTermsApiAddBeginBegin");
      const response = await DataService.post("/api/PaymentTerms", data);
      response.data.index = data.index;
      await commit("purchasePaymentTermsApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("purchasePaymentTermsApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async purchasePaymentTermsUpdateData({ commit }, data) {
    try {
      await commit("purchasePaymentTermsApiUpdateBegin");
      await DataService.put("/api/PaymentTerms/" + data.id, data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("purchasePaymentTermsApiUpdateSuccess", err);

      notificationError(err);
    }
  },

  async purchasePaymentTermsApiDataDelete({ commit }, data) {
    try {
      await commit("purchasePaymentTermsApiDeleteBegin");
      await DataService.delete("/api/PaymentTerms/" + data.id);
      await commit("purchasePaymentTermsApiDeleteSuccess", data.id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("purchasePaymentTermsApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyPaymentTermsPurchaseData({ commit }) {
    await commit("emptyPaymentTermsData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
