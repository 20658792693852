export default {
  categoryApiAddBeginBegin(state) {
    state.loading = true;
  },
  categoryApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiReadBegin(state) {
    state.loading = true;
  },

  categoryApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiUpdateBegin(state) {
    state.loading = true;
  },

  categoryApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiDeleteBegin(state) {
    state.loading = true;
  },

  categoryApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiSingleDataBegin(state) {
    state.loading = true;
  },

  categoryApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  categoryApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiUploadBegin(state) {
    state.fileLoading = true;
  },

  categoryApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  categoryApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  categoryApiSearchBegin(state) {
    state.loading = true;
  },

  categoryApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  categoryApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  categoryApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  categoryApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  categoryApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  categoryApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
  emptyCategory(state) {
    state.loading = false;
    state.data = [];
  },
};
