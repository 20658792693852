export default {
  auxiliaryChargeApiAddBeginBegin(state) {
    state.loading = true;
  },
  auxiliaryChargeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiReadBegin(state) {
    state.loading = true;
  },

  auxiliaryChargeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiUpdateBegin(state) {
    state.loading = true;
  },

  auxiliaryChargeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiDeleteBegin(state) {
    state.loading = true;
  },

  auxiliaryChargeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiSingleDataBegin(state) {
    state.loading = true;
  },

  auxiliaryChargeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  auxiliaryChargeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  auxiliaryChargeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  auxiliaryChargeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  auxiliaryChargeApiSearchBegin(state) {
    state.loading = true;
  },

  auxiliaryChargeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  auxiliaryChargeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  auxiliaryChargeApiAddBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.index == data.index);
    state.data[foundIndex] = data;
  },
  auxiliaryChargeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  auxiliaryChargeApiReadSuccessFromDeal(state, data) {
    state.loading = false;
    state.data = data.result.map(item => {
      return { ...item, id: undefined, parentType: data.parentType, dealId: null };
    });
  },
  auxiliaryChargeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  auxiliaryChargeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },

  emptyAuxiliaryChargeData(state) {
    state.loading = false;
    state.data = [];
  },
};
