import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "range added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async rangeSubmitData({ commit }, data) {
    try {
      await commit("rangeApiAddBeginBegin");
      const response = await DataService.post("api/Range", data.formState);
      await commit("rangeApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("rangeApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async rangeGetPagedData({ commit }, pagination) {
    try {
      await commit("rangeApiReadBegin");

      const query = await DataService.get(
        `api/Range?OrderBy=name&RangeName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("rangeApiReadSuccess", query);
    } catch (err) {
      await commit("rangeApiReadErr", err);
    }
  },

  async rangeGetAllData({ commit }) {
    try {
      await commit("rangeApiReadBegin");

      const query = await DataService.get(
        `api/Range?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("rangeApiReadSuccess", query.data);
    } catch (err) {
      await commit("rangeApiReadErr", err);
    }
  },

  async rangeInfo({ commit }) {
    try {
      await commit("rangeApiReadBegin");
      const query = await DataService.get(
        `api/Range?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("rangeApiReadSuccess", query.data);
    } catch (err) {
      await commit("rangeApiReadErr", err);
    }
  },

  async rangeUpdateData({ commit }, data) {
    try {
      await commit("rangeApiUpdateBegin");
      const response = await DataService.put(
        `api/Range/${data.formState.id}`,
        data.formState
      );
      await commit("rangeApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("rangeApiUpdateErr", err);

      notificationError(err);
    }
  },

  async rangeApiDataSearch({ commit }, searchItem) {
    try {
      await commit("rangeApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("rangeApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("rangeApiReadSuccess", query.data);
        } catch (err) {
          await commit("rangeApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("rangeApiReadErr", err);
    }
  },

  async rangeApiDataDelete({ commit }, { id }) {
    try {
      await commit("rangeApiDeleteBegin");
      await DataService.delete(`/api/Range/${id}`);
      await commit("rangeApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("rangeApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyRange({ commit }) {
    await commit("emptyRange");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
