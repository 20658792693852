export default {
  productLineApiAddBeginBegin(state) {
    state.loading = true;
  },
  productLineApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiReadBegin(state) {
    state.loading = true;
  },

  productLineApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiUpdateBegin(state) {
    state.loading = true;
  },

  productLineApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiDeleteBegin(state) {
    state.loading = true;
  },

  productLineApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiSingleDataBegin(state) {
    state.loading = true;
  },

  productLineApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productLineApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiUploadBegin(state) {
    state.fileLoading = true;
  },

  productLineApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  productLineApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  productLineApiSearchBegin(state) {
    state.loading = true;
  },

  productLineApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productLineApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productLineApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  productLineApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  productLineApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  productLineApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
