export default {
  productDealApiAddBeginBegin(state) {
    state.loading = true;
  },
  productDealApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiReadBegin(state) {
    state.loading = true;
  },
  productFormApiReadBegin(state) {
    state.loading = true;
  },
  productFormApiReadSucess(state) {
    state.loading = true;
  },

  productDealApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiUpdateBegin(state) {
    state.loading = true;
  },

  productDealApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiDeleteBegin(state) {
    state.loading = true;
  },

  productDealApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiSingleDataBegin(state) {
    state.loading = true;
  },

  productDealApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productDealApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiUploadBegin(state) {
    state.fileLoading = true;
  },

  productDealApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  productDealApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  productDealApiSearchBegin(state) {
    state.loading = true;
  },

  productDealApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  productDealApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  productDealApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.push(data);
  },
  productDealApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  productDealApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex] = data;
  },
  updateProductFiles(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.id);
    state.data[foundIndex].files = data.files;
  },
  productDealApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
  productStockApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  productDealApiRestoreSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyProductData(state) {
    state.loading = false;
    state.data = [];
  },
};
