export default {
  pslApiAddBeginBegin(state) {
    state.loading = true;
  },
  pslApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiReadBegin(state) {
    state.loading = true;
  },

  pslApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiUpdateBegin(state) {
    state.loading = true;
  },

  pslApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiDeleteBegin(state) {
    state.loading = true;
  },

  pslApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiSingleDataBegin(state) {
    state.loading = true;
  },

  pslApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  pslApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiUploadBegin(state) {
    state.fileLoading = true;
  },

  pslApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  pslApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  pslApiSearchBegin(state) {
    state.loading = true;
  },

  pslApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  pslApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  pslApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  pslApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  pslApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  pslApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
  emptyPsl(state) {
    state.loading = false;
    state.data = [];
  },
};
