export default {
  cashOperationTypeApiAddBeginBegin(state) {
    state.loading = true;
  },
  cashOperationTypeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiReadBegin(state) {
    state.loading = true;
  },

  cashOperationTypeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiUpdateBegin(state) {
    state.loading = true;
  },

  cashOperationTypeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiDeleteBegin(state) {
    state.loading = true;
  },

  cashOperationTypeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiSingleDataBegin(state) {
    state.loading = true;
  },

  cashOperationTypeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashOperationTypeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  cashOperationTypeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  cashOperationTypeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  cashOperationTypeApiSearchBegin(state) {
    state.loading = true;
  },

  cashOperationTypeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  cashOperationTypeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  cashOperationTypeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  cashOperationTypeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  cashOperationTypeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  cashOperationTypeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
