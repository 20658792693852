import { createStore } from "vuex";
import themeLayout from "./modules/themeLayout/actionCreator";
import headerSearchData from "./modules/headerSearch/actionCreator";
import auth from "./modules/authentication/axios/actionCreator";
import contactApi from "./modules/contact/actionCreator";
import partnerApi from "./modules/partner/actionCreator";
import bankApi from "./modules/partner/bankDetails/actionCreator";
import dealApi from "./modules/deal/actionCreator";
import saleApi from "./modules/sale/actionCreator";
import productSaleApi from "./modules/sale/product/actionCreator";
import productPurchaseApi from "./modules/purchase/product/actionCreator";
import purchaseApi from "./modules/purchase/actionCreator";
import ProductApi from "./modules/deal/product/actionCreator";
import chartContent from "./modules/chartContent/actionCreator";
import CategoryApi from "./modules/settings/category/actionCreator";
import SizeApi from "./modules/settings/size/actionCreator";
import GradeApi from "./modules/settings/grade/actionCreator";
import InspectionSOWApi from "./modules/settings/inspectionSOW/actionCreator";
import IncotermApi from "./modules/settings/incoterm/actionCreator";
import HSCodeApi from "./modules/settings/hs/actionCreator";
import UnitApi from "./modules/settings/unit/actionCreator";
import ThreadApi from "./modules/settings/thread/actionCreator";
import TransportMethodApi from "./modules/settings/transportMethod/actionCreator";
import RangeApi from "./modules/settings/range/actionCreator";
import PslApi from "./modules/settings/psl/actionCreator";
import WeightApi from "./modules/settings/weight/actionCreator";
import CurrencyApi from "./modules/settings/currency/actionCreator";
import ActivityApi from "./modules/settings/activity/actionCreator";
import ProductLineApi from "./modules/settings/productLine/actionCreator";
import FileApi from "./modules/file/actionCreator";
import CountryApi from "./modules/country/actionCreator";
import AccountApi from "./modules/finances/accounts/actionCreator";
import BankApi from "./modules/finances/bank/actionCreator";
import CashApi from "./modules/finances/cash/actionCreator";
import CashOperationApi from "./modules/finances/operations/cash/actionCreator";
import BankOperationApi from "./modules/finances/operations/bank/actionCreator";
import ExpenseOperationApi from "./modules/finances/operations/expense/actionCreator";
import CashOperationTypeApi from "./modules/finances/operations/cash/type/actionCreator";
import ExpenseTypeApi from "./modules/finances/operations/bank/type/actionCreator";
import PaymentMethodApi from "./modules/finances/paymentMethod/actionCreator";
import PaymentTermOptionApi from "./modules/finances/PaymentTermOption/actionCreator";
import AuxiliaryChargeApi from "./modules/auxiliaryCharge/actionCreator";
import PurchasePaymentTermsApi from "./modules/purchase/paymentTerms/actionCreator";
import SalePaymentTermsApi from "./modules/sale/paymentTerms/actionCreator";
import SingleProductApi from "./modules/product/actionCreator";
import historyApi from "./modules/history/actionCreator";

export default createStore({
  modules: {
    themeLayout,
    headerSearchData,
    auth,
    partnerApi,
    HSCodeApi,
    contactApi,
    chartContent,
    dealApi,
    SizeApi,
    saleApi,
    ProductApi,
    productSaleApi,
    productPurchaseApi,
    CategoryApi,
    GradeApi,
    UnitApi,
    ThreadApi,
    RangeApi,
    CurrencyApi,
    ActivityApi,
    WeightApi,
    IncotermApi,
    FileApi,
    bankApi,
    ProductLineApi,
    CountryApi,
    AccountApi,
    BankApi,
    CashApi,
    CashOperationApi,
    BankOperationApi,
    ExpenseOperationApi,
    CashOperationTypeApi,
    ExpenseTypeApi,
    purchaseApi,
    PaymentMethodApi,
    PaymentTermOptionApi,
    PurchasePaymentTermsApi,
    SalePaymentTermsApi,
    SingleProductApi,
    AuxiliaryChargeApi,
    historyApi,
    PslApi,
    InspectionSOWApi,
    TransportMethodApi
  },
});
