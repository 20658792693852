export default {
  gradeApiAddBeginBegin(state) {
    state.loading = true;
  },
  gradeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiReadBegin(state) {
    state.loading = true;
  },

  gradeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiUpdateBegin(state) {
    state.loading = true;
  },

  gradeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiDeleteBegin(state) {
    state.loading = true;
  },

  gradeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiSingleDataBegin(state) {
    state.loading = true;
  },

  gradeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  gradeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  gradeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  gradeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  gradeApiSearchBegin(state) {
    state.loading = true;
  },

  gradeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  gradeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  gradeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  gradeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  gradeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  gradeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },

  emptyGrade(state) {
    state.loading = false;
    state.data = [];
  },
};
