import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Payment term added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async paymentTermOptionSubmitData({ commit }, data) {
    try {
      await commit("paymentTermOptionApiAddBeginBegin");
      const response = await DataService.post(
        "api/PaymentTermOption",
        data.formState
      );
      await commit("paymentTermOptionApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("paymentTermOptionApiAddBeginErr", err);

      notificationError(err);
    }
  },

  async paymentTermOptionGetAllData({ commit }) {
    try {
      await commit("paymentTermOptionApiReadBegin");

      const query = await DataService.get(
        `api/PaymentTermOption?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("paymentTermOptionApiReadSuccess", query.data);
    } catch (err) {
      await commit("paymentTermOptionApiReadErr", err);
    }
  },

  async paymentTermOptionGetPagedData({ commit }, pagination) {
    try {
      await commit("paymentTermOptionApiReadBegin");

      const response = await DataService.get(
        `api/PaymentTermOption?OrderBy=name&PaymentTypeName=${pagination.searchValue}&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("paymentTermOptionApiReadSuccess", response);
    } catch (err) {
      await commit("paymentTermOptionApiReadErr", err);
    }
  },

  async paymentTermOptionInfo({ commit }) {
    try {
      await commit("paymentTermOptionApiReadBegin");
      const query = await DataService.get(
        `api/PaymentTermOption?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("paymentTermOptionApiReadSuccess", query.data);
    } catch (err) {
      await commit("paymentTermOptionApiReadErr", err);
    }
  },

  async paymentTermOptionUpdateData({ commit }, data) {
    try {
      await commit("paymentTermOptionApiUpdateBegin");
      const response = await DataService.put(
        `api/PaymentTermOption/${data.formState.id}`,
        data.formState
      );
      await commit("paymentTermOptionApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("paymentTermOptionApiUpdateErr", err);

      notificationError(err);
    }
  },

  async paymentTermOptionApiDataSearch({ commit }, searchItem) {
    try {
      await commit("paymentTermOptionApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("paymentTermOptionApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("paymentTermOptionApiReadSuccess", query.data);
        } catch (err) {
          await commit("paymentTermOptionApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("paymentTermOptionApiReadErr", err);
    }
  },

  async paymentTermOptionApiDataDelete({ commit }, { id }) {
    try {
      await commit("paymentTermOptionApiDeleteBegin");
      await DataService.delete(`/api/PaymentTermOption/${id}`);
      await commit("paymentTermOptionApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("paymentTermOptionApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyPaymentType({ commit }) {
    await commit("emptyPaymentType");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
